import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import AdsGif from "@/assets/images/ads-gif.gif";
import { applyEllipsis } from "@/utils/helper-functions";
import {
  VideoContentData,
  VideoContentDataText,
  VideoContentImg,
} from "./TopAdPerformance.styles";
import { useQuery } from "@tanstack/react-query";
import {
  PAGINATION_LIMIT,
  QUERY_KEY_TOP_PERFORMING_ADS,
} from "@/constants/api";
import { getTopPerformingAds } from "@/network/analytics";
import { DateTime } from "luxon";
import { useState } from "react";
import { ITopAdPerformanceProps } from "./TopAdPerformance.types";

interface DataType {
  key: string;
  videoImage: string;
  videoTitle: string;
  videoContent: string;
  view: number;
  likes: number;
  comments: number;
  tribe: string;
  advertiser: string;
  date: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { videoContent, videoImage, videoTitle }) => (
      <VideoContentData>
        <VideoContentImg src={videoImage} alt="" />
        <VideoContentDataText>
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {videoTitle}
          </StyledTypography>
          <StyledTypography
            as="p"
            $variantColor="black80"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoContent, 113)}
          </StyledTypography>
        </VideoContentDataText>
      </VideoContentData>
    ),
    width: "40%",
  },
  {
    title: "Advertiser",
    key: "advertiser",
    render: (_, { advertiser }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {advertiser}
      </StyledTypography>
    ),
    width: "8%",
  },
  {
    title: "View",
    key: "view",
    render: (_, { view }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {view}
      </StyledTypography>
    ),
    width: "8%",
  },
  {
    title: "Likes",
    key: "likes",
    render: (_, { likes }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {likes}
      </StyledTypography>
    ),
    width: "8%",
  },
  {
    title: "Comments",
    key: "comments",
    render: (_, { comments }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {comments}
      </StyledTypography>
    ),
    width: "10%",
  },
  {
    title: "Tribe",
    key: "tribe",
    render: (_, { tribe }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {tribe}
      </StyledTypography>
    ),
    width: "9%",
  },
  {
    title: " Date Published",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {date}
      </StyledTypography>
    ),
    width: "14%",
  },
];

const TopAdPerformance: React.FC<ITopAdPerformanceProps> = ({
  advertiserId,
}) => {
  const [page, setPage] = useState<number>(1);
  const {
    data: topPerformingAdsData,
    isPending: isPendingTopAdPerformingMetrics,
  } = useQuery({
    queryKey: [QUERY_KEY_TOP_PERFORMING_ADS, page, advertiserId],
    queryFn: async () =>
      getTopPerformingAds({
        limit: PAGINATION_LIMIT,
        page: page,
        userId: advertiserId,
      }),
  });
  console.log(topPerformingAdsData);
  if (topPerformingAdsData && "error" in topPerformingAdsData) {
    return null;
  }

  return (
    <>
      <StyledFlex justify="space-between" align="center">
        <StyledTypography
          $variantColor="black100"
          $variantSize="lg"
          $fontWeight={500}
        >
          Top Performing Ad
        </StyledTypography>
        {/* <StyledFlex align="center">
          <StyledTypography
            $variantColor="primary"
            $variantSize="md"
            $fontWeight={400}
          >
            View All
          </StyledTypography>
          <ArrowRighTwo />
        </StyledFlex> */}
      </StyledFlex>
      <Table
        pageSize={PAGINATION_LIMIT}
        onPaginationChange={(page) => {
          setPage(page);
          console.log(page);
        }}
        columns={columns}
        dataSource={
          topPerformingAdsData && topPerformingAdsData.data.length
            ? topPerformingAdsData.data.map(
                ({
                  advert: {
                    id,
                    user: {
                      profile: { firstName, lastName },
                    },
                    description,
                    createdAt,
                    title,
                    thumbnail,
                    metadata,
                    tribe,
                  },
                }) => ({
                  key: id,
                  videoImage: thumbnail?.publicURL ?? AdsGif,
                  videoTitle: title,
                  videoContent: description,
                  view: metadata?.totalViews ?? 0,
                  likes: metadata?.totalLikes ?? 0,
                  comments: metadata?.totalComments ?? 0,
                  tribe: tribe?.name ?? "",
                  advertiser: `${firstName} ${lastName}`,
                  date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
                })
              )
            : []
        }
        isPaginated={true}
        total={
          (topPerformingAdsData && topPerformingAdsData.totalDocuments) ?? 0
        }
        loading={isPendingTopAdPerformingMetrics}
      />
    </>
  );
};

export default TopAdPerformance;
