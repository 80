import {
  EditAdvertiserFieldType,
  IEditAdvertiserModalProps,
} from "./EditAdvertiserModal.types";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  StyledInput,
  StyledTextArea,
} from "@/components/Dashboard/Input/Input.styles";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import { At, Email, Phone } from "@/assets/vectors";
import Modal from "@/components/Dashboard/Modal/Modal";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { Form, message } from "antd";
import { required } from "@/utils/validation";
import { QUERY_KEY_ADVERTISERS, QUERY_KEY_TRIBES } from "@/constants/api";
import { updateAdvertiserProfile, updateUserProfile } from "@/network/user";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EditButtonContainer } from "./EditAdvertiserModal.styles";
import { useEffect } from "react";
import Select from "@/components/Dashboard/Select/Select";
import { getTribes } from "@/network/tribe";

const EditAdvertiserModal: React.FC<IEditAdvertiserModalProps> = ({
  setShowEditModal,
  showEditModal,
  initialValues,
  id,
}) => {
  //antd form instance
  const [form] = Form.useForm<EditAdvertiserFieldType>();

  //query
  const { data: tribesData, isPending: isPendingTribe } = useQuery({
    queryKey: [QUERY_KEY_TRIBES],
    queryFn: async () =>
      getTribes({
        limit: 100,
        page: 1,
      }),
  });

  // Access the client
  const queryClient = useQueryClient();

  // Mutation to update advertiser profile
  const {
    mutateAsync: mutateEdiAdvertiser,
    isPending: isPendingEdiAdvertiser,
  } = useMutation({
    mutationFn: updateAdvertiserProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const handleEdit = async (values: EditAdvertiserFieldType) => {
    console.log(values);
    // Compare the new values with the initial values
    const updatedValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (value !== initialValues[key as keyof EditAdvertiserFieldType]) {
        acc[key as keyof EditAdvertiserFieldType] = value;
      }
      return acc;
    }, {} as Partial<EditAdvertiserFieldType>);

    // If no values have changed, return early
    if (Object.keys(updatedValues).length === 0) {
      message.info("No changes detected.");
      return;
    }
    const res = await mutateEdiAdvertiser({
      id: `${id}`,
      ...updatedValues, // Send only updated values
    });

    if ("error" in res) {
      message.error(res?.message ?? res.error);
      return;
    } else {
      message.success(res.message ?? "Advertiser updated successfully!");
    }
    setShowEditModal(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      description: initialValues.description,
      industry: initialValues.industry,
      name: initialValues.name,
      type: initialValues.type,
      phoneNumber: initialValues.phoneNumber,
    });
  }, [initialValues]);

  const tribes = tribesData && "error" in tribesData ? null : tribesData?.data;

  return (
    <Modal
      width={788}
      isModalOpen={showEditModal}
      setIsModalOpen={setShowEditModal}
      heading="Edit Profile"
      footer={
        <EditButtonContainer justify="stretch">
          <StyledButton
            onClick={() => {
              setShowEditModal(false);
            }}
            type="default"
          >
            Cancel
          </StyledButton>
          <StyledButton
            key="submit"
            htmlType="submit"
            form="edit-advertiser"
            type="primary"
            loading={isPendingEdiAdvertiser}
            disabled={isPendingEdiAdvertiser}
          >
            Update
          </StyledButton>
        </EditButtonContainer>
      }
    >
      <StyledForm
        form={form}
        layout="vertical"
        name="edit-advertiser"
        onFinish={handleEdit}
      >
        <StyledFlex gap={16} vertical $alignSelf="stretch">
          <StyledForm.Item<EditAdvertiserFieldType>
            name="name"
            // rules={[required("Please input the first name!")]}
            label="Name"
          >
            <StyledInput type="text" placeholder="Please input a name" />
          </StyledForm.Item>
          <StyledForm.Item<EditAdvertiserFieldType>
            name="type"
            // rules={[
            //   required("Please input your Email!"),
            // ]}
            label="Type"
          >
            <Select
              placeholder="Select a type"
              options={[
                {
                  label: "AGENCY",
                  value: "AGENCY",
                },
                {
                  label: "BRAND",
                  value: "BRAND",
                },
              ]}
            />
          </StyledForm.Item>
          <StyledForm.Item<EditAdvertiserFieldType>
            name="phoneNumber"
            // rules={[required("Please input your Phone number!")]}
            label="Phone Number"
          >
            <StyledInput
              prefix={<Phone />}
              type="text"
              placeholder="Please input a phone number"
            />
          </StyledForm.Item>
          <StyledForm.Item<EditAdvertiserFieldType>
            name="industry"
            // rules={[required("Please input the username!")]}
            label="Industry"
          >
            <Select
              placeholder="Select a type"
              options={
                tribes?.map((item) => ({
                  label: item.name,
                  value: item.name,
                })) ?? []
              }
              loading={isPendingTribe}
            />
          </StyledForm.Item>
          <StyledForm.Item<EditAdvertiserFieldType>
            name="description"
            // rules={[required("Please input the gender!")]}
            label="Description"
          >
            <StyledTextArea placeholder="Please enter a description " />
          </StyledForm.Item>
        </StyledFlex>
      </StyledForm>
    </Modal>
  );
};

export default EditAdvertiserModal;
