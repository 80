import { Camera } from "@/assets/vectors";
import { QUERY_KEY_CURRENT_ADMIN } from "@/constants/api";
import { createPresignedAvatarUrl } from "@/network/user";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message, Upload } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import React, { useState } from "react";
import styled from "styled-components";

const StyledImageUpload = styled(Upload)`
  &.ant-upload-picture-card-wrapper {
    width: 160px;
    height: 160px;
    position: relative;
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    border: ${({ theme }) => `1px solid ${theme.color.white}`};
    &:hover {
      border-color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const StyledCameraWrapper = styled.div`
  position: absolute;
  right: 5px;
  bottom: 5px;
`;

const ImageUpload: React.FC<{
  url?: string;
}> = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  // Access the client
  const queryClient = useQueryClient();

  const {
    mutateAsync: mutateCreatePresignedAvatarUrl,
    isPending: isPendingCreatePresignedAvatarUrl,
  } = useMutation({
    mutationFn: createPresignedAvatarUrl,
  });

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    if (isJpgOrPng && isLt2M) {
      setLoading(true);
      const signedURLRes = await mutateCreatePresignedAvatarUrl({
        filename: file.name,
      });
      if ("error" in signedURLRes) {
        message.error(signedURLRes.message);
        return false;
      } else {
        try {
          const response = await fetch(signedURLRes?.data?.signedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (response.ok) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY_CURRENT_ADMIN],
            });
          } else {
            message.error(`❌ Upload failed:, ${response.statusText}`);
          }
        } catch (error) {
          message.error(`${error}`);
        } finally {
          setLoading(false);
        }
      }
    }
    return false;
  };

  const uploadButton = (
    <div>
      {isPendingCreatePresignedAvatarUrl || loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <StyledImageUpload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      disabled={isPendingCreatePresignedAvatarUrl || loading}
      showUploadList={false}
      beforeUpload={beforeUpload}
    >
      {url ? (
        <img src={url} alt="avatar" style={{ width: "100%" }} />
      ) : (
        uploadButton
      )}
      <StyledCameraWrapper>
        <Camera />
      </StyledCameraWrapper>
    </StyledImageUpload>
  );
};

export default ImageUpload;
