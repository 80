import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  ProgressColumnContainer,
  SearchContainer,
  UserAnalyticsFilterContainer,
  UserAnalyticsLayout,
  ViewersGenderChart,
  ViewersLocation,
  WatchersAgeChart,
  WatchersImg,
} from "./UserAnalytics.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import Select from "@/components/Dashboard/Select/Select";
import { Download, Search } from "@/assets/vectors";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import { ConfigProvider, Progress, Spin } from "antd";
import { userAnalyticsAntdTheme } from "./UserAnalytics.theme";
import RangePicker from "@/components/Dashboard/RangePicker/RangePicker";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledExportButton } from "@/components/Dashboard/Button/Button.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import { viewersLocation } from "@/utils/mock-data";
import PieChart from "@/components/Dashboard/PieChart/PieChart";
import BarChart from "@/components/Dashboard/BarChart/BarChart";
import {
  abbreviateNumberToK,
  getRandomColor,
  reduceArray,
} from "@/utils/helper-functions";
import { useQuery } from "@tanstack/react-query";
import {
  getTopViewersLocation,
  getTopWatchersAge,
  getTopWatchersGender,
} from "@/network/analytics";
import {
  QUERY_KEY_RECENT_USERS,
  QUERY_KEY_TOP_VIEWERS_LOCATION,
  QUERY_KEY_TOP_WATCHERS_AGE,
  QUERY_KEY_TOP_WATCHERS_GENDER,
  QUERY_KEY_TRIBES,
} from "@/constants/api";
import { getRecentUsers } from "@/network/user";
import Avatar from "@/assets/images/avatar.svg";
import { getTribes } from "@/network/tribe";
import { useState } from "react";

interface DataType {
  key: string;
  watchersImg: string;
  watchersName: string;
  email: string;
  phoneNumber: string;
  gender: "string";
  date: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Name",
    key: "watchersName",
    render: (_, { watchersImg, watchersName }) => (
      <StyledFlex gap="12px" align="center">
        <WatchersImg src={watchersImg} alt="" />
        <StyledTypography
          as="p"
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {watchersName}
        </StyledTypography>
      </StyledFlex>
    ),
    width: "25%",
  },
  {
    title: "Email",
    key: "email",
    render: (_, { email }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {email}
      </StyledTypography>
    ),
    width: "27%",
  },
  {
    title: "Phone Number",
    key: "phoneNumber",
    render: (_, { phoneNumber }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {phoneNumber}
      </StyledTypography>
    ),
    width: "17%",
  },
  {
    title: "Gender",
    key: "gender",
    render: (_, { gender }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {gender}
      </StyledTypography>
    ),
    width: "15%",
  },
  {
    title: "Date Joined",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {date}
      </StyledTypography>
    ),
    width: "16%",
  },
];

const UserAnalytics = () => {
  // states
  const [tribeId, setTribeId] = useState<string | undefined>();

  // queries
  const { data: topWatchersAgeData } = useQuery({
    queryKey: [QUERY_KEY_TOP_WATCHERS_AGE],
    queryFn: getTopWatchersAge,
  });

  const { data: topWatchersGenderData } = useQuery({
    queryKey: [QUERY_KEY_TOP_WATCHERS_GENDER],
    queryFn: getTopWatchersGender,
  });

  const { data: topViewersLocationData } = useQuery({
    queryKey: [QUERY_KEY_TOP_VIEWERS_LOCATION],
    queryFn: getTopViewersLocation,
  });

  const { data: recentUsersData, isPending: isPendingRecentUsers } = useQuery({
    queryKey: [QUERY_KEY_RECENT_USERS],
    queryFn: async () =>
      getRecentUsers({
        limit: 5,
        page: 1,
      }),
  });

  const { data: tribesData, isPending: isPendingTribe } = useQuery({
    queryKey: [QUERY_KEY_TRIBES],
    queryFn: async () =>
      getTribes({
        limit: 100,
        page: 1,
      }),
  });

  const tribes = tribesData && "error" in tribesData ? null : tribesData?.data;

  const topWatchersGender =
    topWatchersGenderData && "error" in topWatchersGenderData
      ? null
      : topWatchersGenderData?.data;

  const topWatchersAge =
    topWatchersAgeData && "error" in topWatchersAgeData
      ? null
      : topWatchersAgeData?.data;

  const topViewersLocation =
    topViewersLocationData && "error" in topViewersLocationData
      ? null
      : topViewersLocationData?.data;

  const recentUsers =
    recentUsersData && "error" in recentUsersData
      ? null
      : recentUsersData?.data;

  return (
    <ConfigProvider theme={userAnalyticsAntdTheme}>
      <UserAnalyticsLayout gap={32} vertical $alignSelf="stretch">
        <StyledFlex gap={4} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            User Analytics and Database
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="sm">
            View data on the total Shekels earned by users to understand the
            overall user engagement and rewards distribution.
          </StyledTypography>
        </StyledFlex>
        <StyledDivider />
        <StyledFlex gap={32} align="center" justify="space-between">
          <StyledFlex gap={32} flex="1" align="center">
            <UserAnalyticsFilterContainer>
              <RangePicker
                ranges={{
                  Today: [
                    DateTime.now().startOf("day"),
                    DateTime.now().endOf("day"),
                  ],
                  "This Week": [
                    DateTime.now().startOf("week").startOf("day"),
                    DateTime.now().endOf("week").endOf("day"),
                  ],
                  "This Month": [
                    DateTime.now().startOf("month").startOf("day"),
                    DateTime.now().endOf("month").endOf("day"),
                  ],
                  "This Year": [
                    DateTime.now().startOf("year").startOf("day"),
                    DateTime.now().endOf("year").endOf("day"),
                  ],
                }}
              />
            </UserAnalyticsFilterContainer>
            <UserAnalyticsFilterContainer>
              <Select
                labelInValue
                onSelect={(value) => {
                  setTribeId?.(value?.value === "all" ? "" : `${value?.value}`);
                }}
                onSearch={() => console.log("Eee")}
                showSearch
                placeholder="Filter Tribe"
                filterOption={false}
                options={[
                  {
                    label: "All Tribes",
                    value: "all",
                  },
                  ...(tribes?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) ?? []),
                ]}
                loading={isPendingTribe}
                notFoundContent={isPendingTribe ? <Spin size="small" /> : null}
              />
            </UserAnalyticsFilterContainer>
          </StyledFlex>
          <StyledExportButton type="primary">
            <Download />
            <span>Export</span>
          </StyledExportButton>
        </StyledFlex>
        <StyledFlex align="stretch" gap={24} $alignSelf="stretch">
          {[
            {
              title: "New users",
              value: "100",
            },
            {
              title: "Users using the platform",
              value: "13%",
            },
            {
              title: "Numbers viewed ads",
              value: "50%",
            },
            {
              title: "Frequency of app usage",
              value: "25%",
            },
          ].map((item) => (
            <StyledFlex
              key={item.title}
              flex={"1 1 0"}
              $alignSelf="stretch"
              align="center"
            >
              <StyledCard>
                <StyledFlex gap={8} $alignSelf="stretch" vertical>
                  <StyledTypography
                    $variantColor="black90"
                    $variantSize="sm"
                    $fontWeight={500}
                  >
                    {item.title}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="black100"
                    $variantSize="2xl"
                    $fontWeight={500}
                  >
                    {item.value}
                  </StyledTypography>
                </StyledFlex>
              </StyledCard>
            </StyledFlex>
          ))}
        </StyledFlex>
        <StyledFlex justify="space-between" gap={32} align="stretch">
          <WatchersAgeChart>
            <StyledTypography
              $variantColor="black100"
              $variantSize="md"
              $fontWeight={500}
              $mb={"24px"}
            >
              Watchers Age
            </StyledTypography>
            <BarChart
              series={[
                {
                  data: topWatchersAge?.map((item) => item.count) ?? [],
                },
              ]}
              categories={topWatchersAge?.map((item) => item.ageGroup) ?? []}
            />
          </WatchersAgeChart>
          <ViewersGenderChart justify="space-between" vertical>
            <StyledTypography
              $variantColor="black100"
              $variantSize="md"
              $fontWeight={500}
              $mb={"24px"}
            >
              Viewers Gender
            </StyledTypography>
            <StyledFlex flex="1" align="center" justify="center">
              <PieChart
                width={"100%"}
                height={280}
                categories={
                  topWatchersGender?.map((data) => ({
                    color: getRandomColor(),
                    label: data?.gender,
                    percent: data.count,
                  })) ?? []
                }
                series={topWatchersGender?.map((item) => item.count) ?? []}
              />
            </StyledFlex>
          </ViewersGenderChart>
        </StyledFlex>
        <ViewersLocation>
          <StyledFlex vertical gap={24}>
            <StyledTypography
              $variantColor="black100"
              $variantSize="md"
              $fontWeight={500}
              $mb={"24px"}
            >
              Viewers Location
            </StyledTypography>
            {/* <StyledFlex justify="space-between">
              <ProgressColumnContainer>
                <StyledFlex $width={"100%"} vertical gap={24}>
                  {topViewersLocation
                    ?.slice(0, 4)
                    .map(({ country, location, percent }, index) => (
                      <StyledFlex vertical key={index}>
                        <StyledFlex
                          justify="space-between"
                          gap={24}
                          align="center"
                        >
                          <StyledTypography
                            as="p"
                            $variantColor="black100"
                            $variantSize="md"
                          >
                            {location}{" "}
                            <StyledTypography
                              as="span"
                              $variantColor="black90"
                              $variantSize="md"
                            >
                              ({country})
                            </StyledTypography>
                          </StyledTypography>
                          <StyledTypography
                            as="p"
                            $variantColor="black90"
                            $variantSize="md"
                          >
                            {abbreviateNumberToK(percent)}
                          </StyledTypography>
                        </StyledFlex>
                        <Progress
                          percent={
                            (percent /
                              reduceArray<typeof viewersLocation>(
                                viewersLocation,
                                "percent"
                              )) *
                            100
                          }
                          showInfo={false}
                        />
                      </StyledFlex>
                    ))}
                </StyledFlex>
              </ProgressColumnContainer>
              <ProgressColumnContainer>
                <StyledFlex $width={"100%"} vertical gap={24}>
                  {topViewersLocation
                    ?.slice(4, 12)
                    .map(({ country, location, percent }, index) => (
                      <StyledFlex vertical key={index}>
                        <StyledFlex
                          justify="space-between"
                          gap={24}
                          align="center"
                        >
                          <StyledTypography
                            as="p"
                            $variantColor="black100"
                            $variantSize="md"
                          >
                            {location}{" "}
                            <StyledTypography
                              as="span"
                              $variantColor="black90"
                              $variantSize="md"
                            >
                              ({country})
                            </StyledTypography>
                          </StyledTypography>
                          <StyledTypography
                            as="p"
                            $variantColor="black90"
                            $variantSize="md"
                          >
                            {abbreviateNumberToK(percent)}
                          </StyledTypography>
                        </StyledFlex>
                        <Progress
                          percent={
                            (percent /
                              reduceArray<typeof viewersLocation>(
                                viewersLocation,
                                "percent"
                              )) *
                            100
                          }
                          showInfo={false}
                        />
                      </StyledFlex>
                    ))}
                </StyledFlex>
              </ProgressColumnContainer>
            </StyledFlex> */}
          </StyledFlex>
        </ViewersLocation>
        <StyledFlex
          gap={16}
          align="center"
          justify="space-between"
          $alignSelf="stretch"
        >
          <StyledTypography
            $variantColor="gray90"
            $variantSize="md"
            $fontWeight={500}
          >
            Recent Users
          </StyledTypography>
          <SearchContainer>
            <StyledInput prefix={<Search />} placeholder="Search" />
          </SearchContainer>
        </StyledFlex>
        <Table
          loading={isPendingRecentUsers}
          columns={columns}
          dataSource={recentUsers?.map(({ id, email, createdAt, profile }) => ({
            key: `${id}`,
            watchersImg: profile?.avatar ? profile?.avatar?.publicURL : Avatar,
            watchersName: `${profile?.firstName} ${profile?.lastName}`,
            email,
            phoneNumber: profile?.phoneNumber ?? "-",
            gender: profile?.gender ?? "-",
            date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
          }))}
          isPaginated={false}
        />
      </UserAnalyticsLayout>
    </ConfigProvider>
  );
};

export default UserAnalytics;
