import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import ImageUpload from "./ImageUpload";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { Form, message } from "antd";
import { PasswordFieldType, PersonalInfoFieldType } from "./Profile.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { Email, Phone } from "@/assets/vectors";
import { StyledSelect } from "../../Select/Select.styles";
import { StyledProfileForm, StyledProfileWrapper } from "./Profile.styles";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import { QUERY_KEY_CURRENT_ADMIN } from "@/constants/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCurrentAdmin, updateCurrentAdmin } from "@/network/user";
import { useEffect } from "react";

const Profile: React.FC = () => {
  const [form] = Form.useForm<PersonalInfoFieldType>();
  const [passwordForm] = Form.useForm<PasswordFieldType>();

  // Access the client
  const queryClient = useQueryClient();

  const { data: adminData } = useQuery({
    queryKey: [QUERY_KEY_CURRENT_ADMIN],
    queryFn: getCurrentAdmin,
  });

  const { mutateAsync: mutateEditProfile, isPending: isPendingEditProfile } =
    useMutation({
      mutationFn: updateCurrentAdmin,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_CURRENT_ADMIN],
        });
      },
    });

  const onFinish = async (values: PersonalInfoFieldType) => {
    console.log(values);
    // Compare the new values with the initial values
    // const updatedValues = Object.entries(values).reduce((acc, [key, value]) => {
    //   if (user && value !== user[key as keyof AdminFieldType]) {
    //     acc[key as keyof AdminFieldType] = value;
    //   }
    //   return acc;
    // }, {} as Partial<AdminFieldType>);

    // // If no values have changed, return early
    // if (Object.keys(updatedValues).length === 0) {
    //   message.info("No changes detected.");
    //   return;
    // }
    const res = await mutateEditProfile({
      // Send only updated values,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
    });

    if ("error" in res) {
      message.error(res?.message ?? res.error);
      return;
    } else {
      message.success(res.message ?? "Profile updated successfully!");
    }
  };

  useEffect(() => {
    if (adminData && "error" in adminData) {
      return;
    } else {
      form.setFieldsValue({
        firstName: adminData?.data?.profile?.firstName ?? "",
        lastName: adminData?.data?.profile?.lastName ?? "",
        email: adminData?.data?.email ?? "",
        phoneNumber: adminData?.data?.profile?.phoneNumber ?? "",
      });
    }
  }, [adminData]);

  const admin = adminData && "error" in adminData ? null : adminData?.data;
  const profile = admin?.profile;

  return (
    <StyledProfileWrapper gap={24} vertical>
      <StyledFlex gap={24} align="center" justify="start">
        <div>
          <ImageUpload url={profile?.avatar?.publicURL} />
        </div>
        <StyledFlex $flexGrow={1} gap={4} vertical>
          <StyledTypography
            $variantColor="gray90"
            $variantSize="xl-plus"
            $fontWeight={500}
          >
            {profile?.firstName} {profile?.lastName}
          </StyledTypography>
          <StyledTypography
            $variantColor="black90"
            $variantSize="md"
            $fontWeight={400}
          >
            {profile?.username}
          </StyledTypography>
        </StyledFlex>
      </StyledFlex>
      <StyledFlex gap={20} vertical>
        <StyledDivider />
        <StyledFlex gap={56}>
          <StyledFlex vertical>
            <StyledTypography
              $variantColor="black100"
              $variantSize="sm"
              $fontWeight={500}
            >
              Personal info
            </StyledTypography>
            <StyledTypography
              $variantColor="black80"
              $variantSize="sm"
              $fontWeight={400}
            >
              View your Agency info and personal <br /> details.
            </StyledTypography>
          </StyledFlex>
          <StyledProfileForm
            form={form}
            layout="vertical"
            name="personal-info"
            onFinish={onFinish}
          >
            <StyledFlex gap={24} vertical $alignSelf="stretch">
              <StyledFlex align="center" gap={24}>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="firstName"
                  rules={[required("Please input the first name!")]}
                  label="First Name"
                >
                  <StyledInput type="text" placeholder="{AdminFirstName}" />
                </StyledForm.Item>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="lastName"
                  rules={[required("Please input the last name!")]}
                  label="Last Name"
                >
                  <StyledInput type="text" placeholder="{AdminLastName}" />
                </StyledForm.Item>
              </StyledFlex>
              <StyledFlex align="center" gap={24}>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="email"
                  rules={[
                    required("Please input your Email!"),
                    {
                      type: "email",
                      message: "Please input a valid Email!",
                    },
                  ]}
                  label="Email"
                >
                  <StyledInput
                    prefix={<Email />}
                    disabled
                    type="text"
                    placeholder="Enter Email"
                  />
                </StyledForm.Item>
                <StyledForm.Item<PersonalInfoFieldType>
                  name="phoneNumber"
                  rules={[required("Please input your Phone number!")]}
                  label="Phone Number"
                >
                  <StyledInput
                    prefix={<Phone />}
                    type="text"
                    placeholder="{WatchersPhoneNumber}"
                  />
                </StyledForm.Item>
              </StyledFlex>
              <StyledForm.Item<PersonalInfoFieldType>
                name="role"
                // rules={[required("Please input the role!")]}
                label="Role"
              >
                <StyledSelect disabled placeholder="{AdminRole}" />
              </StyledForm.Item>
              <div>
                <StyledButton
                  loading={isPendingEditProfile}
                  disabled={isPendingEditProfile}
                  form={"personal-info"}
                  key="submit"
                  htmlType="submit"
                  type="primary"
                >
                  Save
                </StyledButton>
              </div>
            </StyledFlex>
          </StyledProfileForm>
        </StyledFlex>
        <StyledDivider />
        {/* <StyledFlex gap={56}>
          <StyledFlex vertical>
            <StyledTypography
              $variantColor="black100"
              $variantSize="sm"
              $fontWeight={500}
            >
              Password
            </StyledTypography>
            <StyledTypography
              $variantColor="black80"
              $variantSize="sm"
              $fontWeight={400}
              $mb={"24px"}
            >
              Please enter your current password to <br /> change your password.
            </StyledTypography>

            <StyledButton htmlType="submit" form="password-form" type="primary">
              Change Password
            </StyledButton>
          </StyledFlex>
          <StyledFlex $flexGrow={1} gap={20} vertical>
            <StyledFlex vertical gap={6}>
              <StyledTypography
                $variantColor="black100"
                $variantSize="sm"
                $fontWeight={500}
              >
                Current password
              </StyledTypography>
              <StyledInput disabled type="password" placeholder="••••••••" />
            </StyledFlex>
            <StyledDivider />
            <StyledProfileForm
              form={form}
              layout="vertical"
              name="password-form"
              onFinish={(values) => {
                console.log(values);
              }}
            >
              <StyledFlex gap={24} vertical $alignSelf="stretch">
                <StyledForm.Item<PasswordFieldType>
                  name="newPassword"
                  rules={[required("Please input password!")]}
                  label="New password"
                >
                  <StyledInput type="password" placeholder="••••••••" />
                </StyledForm.Item>
                <StyledForm.Item<PasswordFieldType>
                  name="confirmPassword"
                  rules={[required("Please confirm password!")]}
                  label="Confirm New password"
                >
                  <StyledInput type="password" placeholder="••••••••" />
                </StyledForm.Item>
              </StyledFlex>
            </StyledProfileForm>
          </StyledFlex>
        </StyledFlex> */}
      </StyledFlex>
    </StyledProfileWrapper>
  );
};

export default Profile;
