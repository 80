import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  StyledButton,
  StyledDeleteButton,
} from "@/components/Dashboard/Button/Button.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import Modal from "@/components/Dashboard/Modal/Modal";
import { message } from "antd";
import { QUERY_KEY_ADVERTISERS } from "@/constants/api";
import {
  banUser,
  deleteUser,
  suspendUser,
  unSuspendUser,
} from "@/network/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ISuspendDeleteBanAdvertiserModalProps } from "./SuspendDeleteBanAdvertiserModal.types";

const SuspendDeleteBanAdvertiserModal: React.FC<
  ISuspendDeleteBanAdvertiserModalProps
> = ({ actionType, setShowActionModal, showActionModal, user }) => {
  // Access the client
  const queryClient = useQueryClient();

  const {
    mutateAsync: mutateDeleteAdvertiser,
    isPending: isPendingDeleteAdvertiser,
  } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const {
    mutateAsync: mutateBanAdvertiser,
    isPending: isPendingBanAdvertiser,
  } = useMutation({
    mutationFn: banUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const {
    mutateAsync: mutateSuspendAdvertiser,
    isPending: isPendingSuspendAdvertiser,
  } = useMutation({
    mutationFn: suspendUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const {
    mutateAsync: mutateUnSuspendAdvertiser,
    isPending: isPendingUnSuspendAdvertiser,
  } = useMutation({
    mutationFn: unSuspendUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ADVERTISERS],
      });
    },
  });

  const actionText =
    actionType === "delete"
      ? "Delete"
      : actionType === "ban"
      ? "Ban"
      : actionType === "suspend"
      ? "Suspend"
      : "Unsuspend";

  return (
    <Modal
      isModalOpen={showActionModal}
      setIsModalOpen={setShowActionModal}
      heading={`Are you sure you want to ${actionText} this Advertiser?`}
      footer={
        <StyledFlex justify="flex-end">
          <StyledButton
            onClick={() => {
              setShowActionModal(false);
            }}
            type="default"
          >
            Cancel
          </StyledButton>
          <StyledDeleteButton
            loading={
              isPendingDeleteAdvertiser ||
              isPendingSuspendAdvertiser ||
              isPendingUnSuspendAdvertiser ||
              isPendingBanAdvertiser
            }
            form="suspend-adds"
            key="submit"
            htmlType="submit"
            type="primary"
            onClick={async () => {
              if (actionType === "delete") {
                const res = await mutateDeleteAdvertiser({
                  id: `${user?.id}`,
                });
                if ("error" in res) {
                  message.error(res?.message ?? res.error);
                  return;
                } else {
                  message.success(res.message ?? "Tribe deleted successfully!");
                  setShowActionModal(false);
                }
              }
              if (actionType === "ban") {
                const res = await mutateBanAdvertiser({
                  id: `${user?.id}`,
                });
                if ("error" in res) {
                  message.error(res?.message ?? res.error);
                  return;
                } else {
                  message.success(res.message ?? "Tribe banned successfully!");
                  setShowActionModal(false);
                }
              }
              if (actionType === "suspend") {
                const res = await mutateSuspendAdvertiser({
                  id: `${user?.id}`,
                });
                if ("error" in res) {
                  message.error(res?.message ?? res.error);
                  return;
                } else {
                  message.success(
                    res.message ?? "Tribe suspended successfully!"
                  );
                  setShowActionModal(false);
                }
              }
              if (actionType === "unsuspend") {
                const res = await mutateUnSuspendAdvertiser({
                  id: `${user?.id}`,
                });
                if ("error" in res) {
                  message.error(res?.message ?? res.error);
                  return;
                } else {
                  message.success(
                    res.message ?? "Tribe unsuspended successfully!"
                  );
                  setShowActionModal(false);
                }
              }
            }}
          >
            Yes, I want to {actionText} this Advertiser
          </StyledDeleteButton>
        </StyledFlex>
      }
    >
      <StyledFlex gap={38} vertical $alignSelf="stretch">
        <StyledTypography as="p" $variantColor="black100" $variantSize="md">
          You are about to {actionText}{" "}
          <StyledTypography
            as="span"
            $variantColor="black100"
            $variantSize="md"
            $fontWeight={500}
          >
            “{user?.name}”.
          </StyledTypography>
        </StyledTypography>
      </StyledFlex>
    </Modal>
  );
};

export default SuspendDeleteBanAdvertiserModal;
