import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  SearchContainer,
  TransactionHistoryFilterContainerOne,
  TransactionHistoryFilterContainerTwo,
  TransactionHistoryLayout,
  UserImg,
} from "./TransactionHistory.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import Select from "@/components/Dashboard/Select/Select";
import { Download, Flag, Search } from "@/assets/vectors";
import { ConfigProvider, Form, message } from "antd";
import { transactionHistoryAntdTheme } from "./TransactionHistory.theme";
import RangePicker from "@/components/Dashboard/RangePicker/RangePicker";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import {
  StyledButton,
  StyledExportButton,
} from "@/components/Dashboard/Button/Button.styles";
import {
  StyledInput,
  StyledTextArea,
} from "@/components/Dashboard/Input/Input.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import { transactionHistory } from "@/utils/mock-data";
import { StyledPill } from "@/components/Dashboard/Pill/Pill.styles";
import { useCallback, useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { FlagTransctionFieldType } from "./TransactionHistory.types";
import { required } from "@/utils/validation";
import { globalTheme } from "@/theme/theme";
import { PAGINATION_LIMIT, QUERY_KEY_TRANSACTIONS } from "@/constants/api";
import {
  contentReport,
  getTransactions,
  reportTransaction,
} from "@/network/transactions";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ISODateString, Role, TransationType } from "@/types/global";
import { debounce } from "@/utils/helper-functions";
import Avatar from "@/assets/images/avatar.svg";

interface DataType {
  key: string;
  userImg: string;
  userName: string;
  transactionType: TransationType;
  shekelsEarned: string;
  nairaAmount: number;
  date: string;
  userType: string;
  isFlagged: boolean;
  exchangeRate: string;
  id: number;
  reportId: number | null;
}

const userRoleObj = {
  advertiser: "Advertiser",
  user: "Watcher",
  admin: "Admin",
  super_admin: "Super Admin",
};

const TransactionHistory = () => {
  const [role, setRole] = useState<Role | "all">("all");
  const [type, setType] = useState<TransationType | "all">("all");
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [dateRange, setDateRange] = useState<[string, string]>([
    DateTime.now().minus({ year: 1 }).startOf("day").toISO(),
    DateTime.now().endOf("day").toISO(),
  ]);

  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [isFlagged, setIsFlagged] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);
  const [transactionId, setTransactionId] = useState<number | null>(null);

  // Access the client
  const queryClient = useQueryClient();

  const { data: transactionsData, isPending: isPendingTransaction } = useQuery({
    queryKey: [QUERY_KEY_TRANSACTIONS, page, dateRange, search, role, type],
    queryFn: async () =>
      getTransactions({
        limit: PAGINATION_LIMIT,
        page: page,
        search,
        from: dateRange[0] as ISODateString,
        to: dateRange[1] as ISODateString,
        role: role === "all" ? undefined : role,
        type: type === "all" ? undefined : type,
      }),
  });

  const {
    mutateAsync: mutateReportTransaction,
    isPending: isPendingReportTransaction,
  } = useMutation({
    mutationFn: reportTransaction,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_TRANSACTIONS],
      });
    },
  });

  const {
    mutateAsync: mutateContentReport,
    isPending: isPendingContentReport,
  } = useMutation({
    mutationFn: contentReport,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_TRANSACTIONS],
      });
    },
  });

  const [form] = Form.useForm<FlagTransctionFieldType>();
  const showTransactionModal = () => {
    setIsTransactionModalOpen(true);
  };

  const hideTransactionModal = () => {
    setIsTransactionModalOpen(false);
  };

  const onFinish = async (values: FlagTransctionFieldType) => {
    if (isFlagged) {
      if (reportId) {
        const res = await mutateContentReport({
          comment: values.reason,
          id: `${reportId}`,
          status: "DENIED",
        });
        if ("error" in res) {
          message.error(res.error);
          return;
        } else {
          message.success("Unflagged transaction successfully!");
          hideTransactionModal();
          form.resetFields();
        }
      } else {
        hideTransactionModal();
      }
    } else {
      if (transactionId) {
        const res = await mutateReportTransaction({
          message: values.reason,
          transactionId: transactionId,
        });
        if ("error" in res) {
          message.error(res.error);
          return;
        } else {
          message.success("Flagged transaction successfully!");
          hideTransactionModal();
          form.resetFields();
        }
      } else {
        hideTransactionModal();
      }
    }
  };

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
        setPage(1);
      },
      1000,
      false
    ),
    []
  );

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      key: "userName",
      render: (_, { userImg, userName }) => (
        <StyledFlex gap="12px" align="center">
          <UserImg src={userImg} alt="" />
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {userName}
          </StyledTypography>
        </StyledFlex>
      ),
      width: "39%",
    },
    {
      title: "User Type",
      key: "userType",
      render: (_, { userType }) => (
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {userType}
        </StyledTypography>
      ),
      width: "11%",
    },
    {
      title: "Transaction Type",
      key: "transactionType",
      render: (_, { transactionType }) => (
        <StyledPill
          $bgColor={
            transactionType === "CREDIT"
              ? "success50"
              : // : transactionType === "Top-Up"
                // ? "gray100"
                "error50"
          }
          $color={
            transactionType === "CREDIT"
              ? "success700"
              : // : transactionType === "Top-Up"
                // ? "gray700"
                "error700"
          }
        >
          {transactionType}
        </StyledPill>
      ),
      width: "14%",
    },
    {
      title: "Amount",
      key: "shekelsEarned",
      render: (_, { shekelsEarned, nairaAmount }) => (
        <StyledFlex vertical gap={1}>
          <StyledTypography
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {shekelsEarned} Shekels
          </StyledTypography>
          <StyledTypography
            $variantColor="gray500"
            $variantSize="xs"
            $fontWeight={400}
          >
            {nairaAmount.toLocaleString()} in Naira
          </StyledTypography>
        </StyledFlex>
      ),
      width: "10%",
    },
    {
      title: "Transaction Date ",
      key: "date",
      render: (_, { date }) => (
        <StyledTypography
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {date}
        </StyledTypography>
      ),
      width: "13%",
    },
    {
      title: "",
      key: "key",
      render: (_, { isFlagged, id, reportId }) =>
        isFlagged ? (
          <button
            onClick={() => {
              setIsFlagged(true);
              showTransactionModal();
              setReportId(reportId);
            }}
          >
            <StyledTypography
              $variantColor="primary"
              $variantSize="sm"
              $fontWeight={400}
            >
              Flagged
            </StyledTypography>
          </button>
        ) : (
          <button
            onClick={() => {
              setIsFlagged(false);
              showTransactionModal();
              setTransactionId(id);
            }}
          >
            <Flag />
          </button>
        ),
      width: "13%",
    },
  ];

  if (transactionsData && "error" in transactionsData) {
    return null;
  }

  return (
    <ConfigProvider theme={transactionHistoryAntdTheme}>
      {/* modal starts */}
      <ConfigProvider
        theme={{
          components: {
            Button: {
              primaryColor: globalTheme.color.white,
              colorPrimary: globalTheme.color.primary,
            },
          },
        }}
      >
        <Modal
          isModalOpen={isTransactionModalOpen}
          setIsModalOpen={setIsTransactionModalOpen}
          heading={
            !isFlagged
              ? "Are you sure you want to flag this transaction?"
              : "Are you sure you want to unflag this transaction?"
          }
          footer={
            <StyledFlex justify="flex-end">
              <StyledButton onClick={hideTransactionModal} type="default">
                Cancel
              </StyledButton>
              <StyledButton
                disabled={
                  isFlagged
                    ? isPendingContentReport
                    : isPendingReportTransaction
                }
                loading={
                  isFlagged
                    ? isPendingContentReport
                    : isPendingReportTransaction
                }
                form="flag-transaction"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                {isFlagged
                  ? "Yes, Unflag Transactions"
                  : "Yes, Flag Transaction"}
              </StyledButton>
            </StyledFlex>
          }
        >
          <StyledForm
            form={form}
            layout="vertical"
            name="flag-transaction"
            onFinish={onFinish}
          >
            <StyledFlex gap={38} vertical $alignSelf="stretch">
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="md"
              >
                {isFlagged
                  ? "Unflagging this transaction will remove the indication that it requires further investigation"
                  : "Flagging this transaction for further investigation will mark it for additional evaluation."}
              </StyledTypography>
              <StyledForm.Item<FlagTransctionFieldType>
                name="reason"
                rules={[required("Please input your reason!")]}
                label="Give a Reason"
              >
                <StyledTextArea
                  placeholder={
                    isFlagged
                      ? "Please provide a brief reason for unflagging this transaction"
                      : "Please provide a brief reason for flagging this transaction"
                  }
                />
              </StyledForm.Item>
            </StyledFlex>
          </StyledForm>
        </Modal>
      </ConfigProvider>
      {/* modal ends */}
      <TransactionHistoryLayout gap={32} vertical $alignSelf="stretch">
        <StyledFlex gap={4} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            Transaction History
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="sm">
            Payment Processing and Transaction History
          </StyledTypography>
        </StyledFlex>
        <StyledDivider />
        <StyledFlex gap={32} align="center" justify="space-between">
          <StyledFlex gap={16} flex="1" align="center">
            <TransactionHistoryFilterContainerOne>
              <RangePicker
                onChange={(dates) => {
                  if (dates === null) {
                    setDateRange(["", ""]);
                  } else if (dates.length) {
                    setDateRange([
                      dates[0]?.startOf("day").toISO() ?? "",
                      dates[1]?.endOf("day").toISO() ?? "",
                    ]);
                  }
                }}
                value={[
                  dateRange[0] ? DateTime.fromISO(dateRange[0]) : null,
                  dateRange[1] ? DateTime.fromISO(dateRange[1]) : null,
                ]}
                ranges={{
                  Today: [
                    DateTime.now().startOf("day"),
                    DateTime.now().endOf("day"),
                  ],
                  "This Week": [
                    DateTime.now().startOf("week").startOf("day"),
                    DateTime.now().endOf("week").endOf("day"),
                  ],
                  "This Month": [
                    DateTime.now().startOf("month").startOf("day"),
                    DateTime.now().endOf("month").endOf("day"),
                  ],
                  "This Year": [
                    DateTime.now().startOf("year").startOf("day"),
                    DateTime.now().endOf("year").endOf("day"),
                  ],
                }}
              />
            </TransactionHistoryFilterContainerOne>
            <TransactionHistoryFilterContainerOne>
              <Select
                placeholder="Transaction Type"
                options={[
                  {
                    label: "All",
                    value: "all",
                  },
                  {
                    label: "Credit",
                    value: "CREDIT",
                  },
                  {
                    label: "Debit",
                    value: "DEBIT",
                  },
                ]}
                onChange={(value) => {
                  setType(value as TransationType);
                }}
              />
            </TransactionHistoryFilterContainerOne>
            <TransactionHistoryFilterContainerTwo>
              <Select
                placeholder="User Type"
                options={[
                  {
                    label: "All",
                    value: "all",
                  },
                  {
                    label: "Advertiser",
                    value: "advertiser",
                  },
                  {
                    label: "Watcher",
                    value: "user",
                  },
                ]}
                onChange={(value) => {
                  setRole(value as Role);
                }}
              />
            </TransactionHistoryFilterContainerTwo>
          </StyledFlex>
          <SearchContainer>
            <StyledInput
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              prefix={<Search />}
              placeholder="Search"
            />
          </SearchContainer>
          <StyledExportButton type="primary">
            <Download />
            <span>Export</span>
          </StyledExportButton>
        </StyledFlex>
        <StyledFlex vertical gap={16}>
          <StyledTypography
            $variantColor="gray90"
            $variantSize="lg"
            $fontWeight={500}
          >
            Transaction data
          </StyledTypography>
          <Table
            columns={columns}
            pageSize={PAGINATION_LIMIT}
            onPaginationChange={(page) => {
              setPage(page);
            }}
            dataSource={
              transactionsData?.data.map((transaction) => ({
                key: `${transaction.id}`,
                id: transaction.id,
                userImg:
                  transaction.type === "CREDIT"
                    ? transaction.senderWallet.user.profile.avatar?.publicURL ??
                      Avatar
                    : transaction.beneficiaryWallet.user.profile.avatar
                        ?.publicURL ?? Avatar,
                userName: `${
                  transaction.type === "CREDIT"
                    ? transaction.senderWallet.user.profile.firstName
                    : transaction.beneficiaryWallet.user.profile.firstName
                } ${
                  transaction.type === "CREDIT"
                    ? transaction.senderWallet.user.profile.lastName
                    : transaction.beneficiaryWallet.user.profile.lastName
                }`,
                userType:
                  transaction.type === "CREDIT"
                    ? userRoleObj[transaction.senderWallet.user?.role?.name]
                    : userRoleObj[
                        transaction.beneficiaryWallet.user?.role?.name
                      ],
                transactionType: transaction.type,
                shekelsEarned: transaction.amount,
                nairaAmount: transaction.nairaAmount,
                date: DateTime.fromISO(transaction.createdAt).toLocaleString(
                  DateTime.DATETIME_MED
                ),
                isFlagged:
                  transaction.reported ||
                  transaction.reports.some(
                    (report) => report.status === "PENDING"
                  ),
                exchangeRate: transaction.exchangeRate,
                reportId: transaction.reports.length
                  ? transaction.reports.find(
                      (report) => report.status === "PENDING"
                    )?.id ?? null
                  : null,
              })) ?? []
            }
            isPaginated={true}
            total={(transactionsData && transactionsData?.totalDocuments) ?? 0}
            loading={isPendingTransaction}
          />
        </StyledFlex>
      </TransactionHistoryLayout>
    </ConfigProvider>
  );
};

export default TransactionHistory;
