import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const ProfileMetrics = styled(StyledFlex)`
  padding: 15px 48px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.black50}`};
  background: ${({ theme }) => `${theme.color.black50}`};
`;

export const UserProfile = styled.img`
  object-fit: cover;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;
