import { ConfigProvider, TabsProps } from "antd";
import { AdvertisersManagementLayout } from "./AdvertisersManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { StyledTabs } from "@/components/Dashboard/Tabs/Tabs.styles";
import { advertisersManagementAntdTheme } from "./AdvertisersManagement.theme";
import SlideInDrawer from "@/components/Dashboard/SlideInDrawer/SlideInDrawer";
import { useState } from "react";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import AdvertisersTable from "@/components/Dashboard/Advertiser/AdvertisersTable/AdvertisersTable";
import AdvertiserDetails from "@/components/Dashboard/Advertiser/AdvertiserDetails/AdvertiserDetails";
import { QUERY_KEY_ADVERTISER } from "@/constants/api";
import { useQuery } from "@tanstack/react-query";
import { getAdvertiser } from "@/network/user";
import { DateTime } from "luxon";
import UploadedAds from "@/components/Dashboard/Advertiser/UploadedAds/UploadedAds";
import UploadedPosts from "@/components/Dashboard/Advertiser/UploadedPosts/UploadedPosts";

const AdvertisersManagement = () => {
  const onChange = (key: string) => {
    console.log(key);
  };
  const [isOpen, setIsOpen] = useState(false);
  // set advertiser id
  const [id, setId] = useState<number | null>(null);

  const handleClick = (id: number) => {
    setIsOpen(true);
    setId(id);
  };

  //queries
  const { data: advertiserData, isPending: isPendingAdvertiser } = useQuery({
    queryKey: [QUERY_KEY_ADVERTISER, id],
    queryFn: async () => (id ? getAdvertiser(`${id}`) : undefined),
    enabled: !!id,
  });

  const adsItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Uploaded Ads",
      children: <UploadedAds id={id} />,
    },
    {
      key: "2",
      label: "Uploaded Posts",
      children: <UploadedPosts id={id} />,
    },
  ];

  const advertiser =
    advertiserData && "error" in advertiserData ? null : advertiserData?.data;
  const profile = advertiser?.profile;

  return (
    <ConfigProvider theme={advertisersManagementAntdTheme}>
      {/* view watcher details slide in */}
      <SlideInDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <StyledFlex align="center" gap={38} vertical>
          <AdvertiserDetails
            comments={advertiser?.advertisementMetrics?.totalComments ?? 0}
            date={
              advertiser?.createdAt
                ? DateTime.fromISO(advertiser?.createdAt).toFormat(
                    "MMM dd, yyyy"
                  )
                : ""
            }
            email={advertiser?.email ?? ""}
            likes={advertiser?.advertisementMetrics?.totalLikes ?? 0}
            name={`${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`}
            shekelsGiven={advertiser?.advertisementMetrics?.totalSpent ?? 0}
            uploadedAds={advertiser?.totalAdvertisements ?? 0}
            username={profile?.username ?? ""}
            views={advertiser?.advertisementMetrics?.totalViews ?? 0}
            img={profile?.avatar?.publicURL ?? ""}
          />
          <StyledFlex align="stretch" gap={16} vertical $alignSelf="stretch">
            <StyledTabs
              defaultActiveKey="1"
              items={adsItems}
              onChange={onChange}
            />
          </StyledFlex>
        </StyledFlex>
      </SlideInDrawer>
      <AdvertisersManagementLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Advertisers Management
        </StyledTypography>
        <StyledDivider />
        <AdvertisersTable handleRowClick={handleClick} />
      </AdvertisersManagementLayout>
    </ConfigProvider>
  );
};

export default AdvertisersManagement;
