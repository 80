import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { AdRevenueLayout } from "./AdRevenue.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import { ConfigProvider } from "antd";
import { adRevenueAntdTheme } from "./AdRevenue.theme";
import AreaChart from "@/components/Dashboard/AreaChart/AreaChart";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import TopPerformingTribes from "@/components/Dashboard/Analytics/TopPerformingTribes/TopPerformingTribes";
import TopRevenuePerformingAds from "@/components/Dashboard/Analytics/TopRevenuePerformingAds/TopRevenuePerformingAds";
import { useState } from "react";
import {
  QUERY_KEY_METRICS,
  QUERY_KEY_REVENUE_BAR_CHART,
} from "@/constants/api";
import { useQuery } from "@tanstack/react-query";
import { getMetrics, getRevenueBarChart } from "@/network/analytics";
import { ISODateString } from "@/types/global";
import AnalyticsFilter from "@/components/Dashboard/Analytics/AnalyticsFilter/AnalyticsFilter";
import { convertShekelsToNaira } from "@/utils/helper-functions";

const AdRevenue = () => {
  //state
  const [dateRange, setDateRange] = useState<[string, string]>([
    DateTime.now().minus({ year: 1 }).startOf("day").toISO(),
    DateTime.now().endOf("day").toISO(),
  ]);
  const [tribeId, setTribeId] = useState<string>("");
  const [advertiserId, setAdvertiserId] = useState<string>("");

  //queries
  const { data: metricsData } = useQuery({
    queryKey: [QUERY_KEY_METRICS, dateRange, tribeId, advertiserId],
    queryFn: async () =>
      getMetrics({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
        advertiserId: advertiserId || undefined,
        tribeId: tribeId || undefined,
      }),
  });
  const { data: revenueChartData } = useQuery({
    queryKey: [QUERY_KEY_REVENUE_BAR_CHART, dateRange, tribeId, advertiserId],
    queryFn: async () =>
      getRevenueBarChart({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
        advertiserId: advertiserId || undefined,
        tribeId: tribeId || undefined,
      }),
  });

  const revenueChart =
    revenueChartData && "error" in revenueChartData
      ? null
      : revenueChartData?.data;

  const metrics =
    metricsData && "error" in metricsData ? null : metricsData?.data;

  return (
    <ConfigProvider theme={adRevenueAntdTheme}>
      <AdRevenueLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Ad Revenue Analytics
        </StyledTypography>
        <StyledDivider />
        <AnalyticsFilter
          advertiserId={advertiserId}
          tribeId={tribeId}
          setAdvertiserId={setAdvertiserId}
          setTribeId={setTribeId}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <StyledFlex align="stretch" gap={24} $alignSelf="stretch">
          {[
            {
              title: "Total Revenue",
              value: `₦${metrics?.adRevenue?.toLocaleString() ?? 0}`,
              subTitle: "(Lohli's 30% of Total Amount uploaded)",
            },
            {
              title: "Total Ad Budget Allocation",
              value: `₦${convertShekelsToNaira(
                `${metrics?.adBudgetAllocation ?? 0}`
              )?.toLocaleString()}`,
              subTitle: `(${
                metrics?.adBudgetAllocation?.toLocaleString() ?? 0
              } in Shekel)`,
            },
            {
              title: "Total Shekels Earned",
              value: `₦${convertShekelsToNaira(
                `${metrics?.shekelsEarned ?? 0}`
              )?.toLocaleString()}`,
              subTitle: `(${
                metrics?.shekelsEarned?.toLocaleString() ?? 0
              } in Shekel)`,
            },
            {
              title: "Total Watchers Sling Balance ",
              value: `₦${convertShekelsToNaira(
                `${metrics?.slingBalance ?? 0}`
              )?.toLocaleString()}`,
              subTitle: `(${
                metrics?.slingBalance?.toLocaleString() ?? 0
              } in Shekel)`,
            },
          ].map((item) => (
            <StyledFlex
              key={item.title}
              flex={"1 1 0"}
              $alignSelf="stretch"
              align="center"
            >
              <StyledCard>
                <StyledFlex gap={8} $alignSelf="stretch" vertical>
                  <StyledTypography
                    $variantColor="black90"
                    $variantSize="sm"
                    $fontWeight={400}
                  >
                    {item.title}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="black100"
                    $variantSize="2xl"
                    $fontWeight={500}
                  >
                    {item.value}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="black90"
                    $variantSize="xs"
                    $fontWeight={400}
                  >
                    {item.subTitle}
                  </StyledTypography>
                </StyledFlex>
              </StyledCard>
            </StyledFlex>
          ))}
        </StyledFlex>
        <div>
          <StyledFlex vertical gap={"24px"}>
            <StyledTypography
              $variantColor="black100"
              $variantSize="lg"
              $fontWeight={500}
            >
              Revenue
            </StyledTypography>
            <AreaChart
              series={[
                {
                  name: "Revenue",
                  data: Object.entries(revenueChart ?? {})
                    .sort(([a], [b]) => {
                      // Convert to Date objects for accurate comparison
                      const dateA = new Date(
                        a.split("/").reverse().join("-")
                      ).getTime();
                      const dateB = new Date(
                        b.split("/").reverse().join("-")
                      ).getTime();
                      return dateA - dateB;
                    })
                    .map(([date, value]) => ({ x: date, y: value })),
                },
              ]}
              height={320}
            />
          </StyledFlex>
        </div>
        <StyledDivider />
        <TopPerformingTribes
          dateRange={dateRange}
          advertiserId={advertiserId}
          tribeId={tribeId}
        />
        <StyledDivider />
        <TopRevenuePerformingAds
          advertiserId={advertiserId}
          tribeId={tribeId}
        />
      </AdRevenueLayout>
    </ConfigProvider>
  );
};

export default AdRevenue;
