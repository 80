import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { AdPerformanceLayout } from "./AdPerformance.styles";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledCard } from "@/components/Dashboard/Card/Card.styles";
import { ConfigProvider } from "antd";
import { adPerformanceAntdTheme } from "./AdPerformance.theme";
import { DateTime } from "luxon";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import TopPerformingTribes from "@/components/Dashboard/Analytics/TopPerformingTribes/TopPerformingTribes";
import TopRevenuePerformingAds from "@/components/Dashboard/Analytics/TopRevenuePerformingAds/TopRevenuePerformingAds";
import TopAdPerformance from "@/components/Dashboard/Analytics/TopAdPerformance/TopAdPerformance";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_AD_PERFORMING_METRICS } from "@/constants/api";
import { getAdPerformingMetrics } from "@/network/analytics";
import { ISODateString } from "@/types/global";
import AnalyticsFilter from "@/components/Dashboard/Analytics/AnalyticsFilter/AnalyticsFilter";

const AdPerformance = () => {
  //state
  const [dateRange, setDateRange] = useState<[string, string]>([
    DateTime.now().minus({ year: 1 }).startOf("day").toISO(),
    DateTime.now().endOf("day").toISO(),
  ]);
  const [tribeId, setTribeId] = useState<string>("");
  const [advertiserId, setAdvertiserId] = useState<string>("");

  //queries
  const { data: adPerformingMetricsData } = useQuery({
    queryKey: [
      QUERY_KEY_AD_PERFORMING_METRICS,
      dateRange,
      tribeId,
      advertiserId,
    ],
    queryFn: async () =>
      getAdPerformingMetrics({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
        advertiserId: advertiserId || undefined,
        tribeId: tribeId || undefined,
      }),
  });

  const adPerformingMetrics =
    adPerformingMetricsData && "error" in adPerformingMetricsData
      ? null
      : adPerformingMetricsData?.data;

  return (
    <ConfigProvider theme={adPerformanceAntdTheme}>
      <AdPerformanceLayout gap={32} vertical $alignSelf="stretch">
        <StyledTypography
          $variantColor="gray90"
          $variantSize="2xl"
          $fontWeight={500}
        >
          Ad Performanace Analytics
        </StyledTypography>
        <StyledDivider />
        <AnalyticsFilter
          dateRange={dateRange}
          setDateRange={setDateRange}
          advertiserId={advertiserId}
          tribeId={tribeId}
          setAdvertiserId={setAdvertiserId}
          setTribeId={setTribeId}
        />
        <StyledFlex align="stretch" gap={24} $alignSelf="stretch">
          {[
            {
              title: "Total number of Advertisers",
              value: `${
                adPerformingMetrics?.totalAdvertisers?.toLocaleString() ?? 0
              }`,
            },
            {
              title: "Total number of Watchers",
              value: `${
                adPerformingMetrics?.totalWatchers?.toLocaleString() ?? 0
              }`,
            },
            {
              title: "Total watchers Ad views",
              value: `${
                adPerformingMetrics?.totalViewedAds?.toLocaleString() ?? 0
              }`,
            },
            {
              title: "Total watchers who earned",
              value: `${
                adPerformingMetrics?.totalWatchersWhoEarned?.toLocaleString() ??
                0
              }`,
            },
          ].map((item) => (
            <StyledFlex
              key={item.title}
              flex={"1 1 0"}
              $alignSelf="stretch"
              align="center"
            >
              <StyledCard>
                <StyledFlex gap={8} $alignSelf="stretch" vertical>
                  <StyledTypography
                    $variantColor="black90"
                    $variantSize="sm"
                    $fontWeight={500}
                  >
                    {item.title}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="black100"
                    $variantSize="2xl"
                    $fontWeight={500}
                  >
                    {item.value}
                  </StyledTypography>
                </StyledFlex>
              </StyledCard>
            </StyledFlex>
          ))}
        </StyledFlex>
        <TopAdPerformance advertiserId={advertiserId} />
        <StyledDivider />
        <TopPerformingTribes
          dateRange={dateRange}
          advertiserId={advertiserId}
          tribeId={tribeId}
        />
        <StyledDivider />
        <TopRevenuePerformingAds
          advertiserId={advertiserId}
          tribeId={tribeId}
        />
      </AdPerformanceLayout>
    </ConfigProvider>
  );
};

export default AdPerformance;
