import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { IAdvertiserDetailsProps } from "./AdvertiserDetails.types";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { ProfileMetrics, UserProfile } from "./AdvertiserDetails.styles";
import Avatar from "@/assets/images/avatar.svg";
import { abbreviateNumberToK } from "@/utils/helper-functions";

const AdvertiserDetails: React.FC<IAdvertiserDetailsProps> = ({
  comments,
  date,
  img,
  likes,
  name,
  shekelsGiven,
  uploadedAds,
  username,
  views,
  email,
}) => {
  return (
    <StyledFlex $alignSelf="stretch" align="center" gap={38} vertical>
      <StyledFlex $alignSelf="stretch" align="center" gap={16}>
        <UserProfile src={img || Avatar} alt="" />
        <StyledFlex flex={"1"} align="flex-start" gap={8} vertical>
          <StyledTypography
            $variantColor="black100"
            $variantSize="2xl"
            $fontWeight={600}
          >
            {name}
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="md">
            <StyledFlex as="span" align="center" gap={8}>
              <span>@{username}</span> <span> | </span>
              <span>{email}</span>
            </StyledFlex>
          </StyledTypography>
          <StyledTypography $variantColor="black80" $variantSize="md">
            Date Joined: {date}
          </StyledTypography>
        </StyledFlex>
      </StyledFlex>
      <ProfileMetrics $alignSelf="stretch" justify="space-between">
        {[
          {
            value: abbreviateNumberToK(uploadedAds),
            title: "Uploaded ads",
          },
          {
            value: abbreviateNumberToK(likes),
            title: "Likes",
          },
          {
            value: abbreviateNumberToK(comments),
            title: "Comments",
          },
          {
            value: abbreviateNumberToK(views),
            title: "Views",
          },
          {
            value: abbreviateNumberToK(shekelsGiven),
            title: "Shekels Given",
          },
        ].map((item) => (
          <StyledFlex
            key={item.title}
            gap={8}
            $alignSelf="stretch"
            align="center"
            vertical
          >
            <StyledTypography
              $variantColor="black100"
              $variantSize="md"
              $fontWeight={500}
            >
              {item.value}
            </StyledTypography>
            <StyledTypography $variantColor="black90" $variantSize="xs">
              {item.title}
            </StyledTypography>
          </StyledFlex>
        ))}
      </ProfileMetrics>
    </StyledFlex>
  );
};

export default AdvertiserDetails;
