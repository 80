import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const EditButtonContainer = styled(StyledFlex)`
  & > *:first-child {
    flex-grow: 1;
  }
  & > *:last-child {
    flex-grow: 1;
  }
`;
