import { ConfigProvider, Form, message } from "antd";
import {
  ProfileMetrics,
  SearchContainer,
  UserImg,
  UserProfile,
  VideoContentData,
  VideoContentDataText,
  VideoContentImg,
  WatchersBalance,
  WatchersManagementLayout,
} from "./WatchersManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledDivider } from "@/components/Global/Divider/Divider.styles";
import { watchersManagementAntdTheme } from "./WatchersManagement.theme";
import { ColumnsType } from "antd/es/table";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import Table from "@/components/Dashboard/Table/Table";
import { At, Email, Options, Phone, Search } from "@/assets/vectors";
import { useCallback, useState } from "react";
import SlideInDrawer from "@/components/Dashboard/SlideInDrawer/SlideInDrawer";
import {
  abbreviateNumberToK,
  applyEllipsis,
  calculateAge,
  convertShekelsToNaira,
  debounce,
} from "@/utils/helper-functions";
import Avatar from "@/assets/images/avatar.svg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  banUser,
  deleteUser,
  getAdsWatched,
  getWatcher,
  getWatcherStats,
  getWatchers,
  suspendUser,
  unSuspendUser,
  updateUserProfile,
} from "@/network/user";
import {
  PAGINATION_LIMIT,
  QUERY_KEY_ADS_WATCHED,
  QUERY_KEY_ADS_WATCHER_STATS,
  QUERY_KEY_WATCHER,
  QUERY_KEY_WATCHERS,
  QUERY_KEY_WATCHER_WALLET,
} from "@/constants/api";
import { DateTime } from "luxon";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import Dropdown from "@/components/Dashboard/Dropdown/Dropdown";
import Modal from "@/components/Dashboard/Modal/Modal";
import { StyledButton } from "@/components/Auth/Button/Button.style";
import { StyledDeleteButton } from "@/components/Dashboard/Button/Button.styles";
import { EditUserFieldType } from "./WatchersManagement.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { StatusType } from "@/types/global";
import { states } from "@/utils/states";
import Select from "@/components/Dashboard/Select/Select";
import { StyledStatusTab } from "@/components/Dashboard/StatusTab/StatusTab.styles";

interface DataType {
  key: string;
  userName: string;
  firstName: string;
  lastName: string;
  userImg: string;
  email: string;
  phoneNumber: string;
  gender: string;
  address: string;
  age: number;
  date: string;
  id: number;
  status: string;
}

interface AdsDataType {
  key: string;
  videoImage: string;
  videoTitle: string;
  videoContent: string;
  advertiser: string;
  tribe: string;
  earnings: number;
  date: string;
}

//table column
const adColumns: ColumnsType<AdsDataType> = [
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { videoContent, videoImage, videoTitle }) => (
      <VideoContentData>
        <VideoContentImg src={videoImage} alt="" />
        <VideoContentDataText>
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoTitle, 37)}
          </StyledTypography>
          <StyledTypography
            as="p"
            $variantColor="black80"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoContent, 75)}
          </StyledTypography>
        </VideoContentDataText>
      </VideoContentData>
    ),
    width: "44%",
  },
  {
    title: "Advertiser",
    key: "advertiser",
    render: (_, { advertiser, key }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {advertiser}
      </StyledTypography>
    ),
    width: "13%",
  },
  {
    title: "Tribe",
    key: "tribe",
    render: (_, { tribe }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {tribe}
      </StyledTypography>
    ),
    width: "10%",
  },
  {
    title: "Shekels Earned",
    key: "earnings",
    render: (_, { earnings }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {earnings} Shekels
      </StyledTypography>
    ),
    width: "17%",
  },
  {
    title: "Date Earned",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {date}
      </StyledTypography>
    ),
    width: "16%",
  },
];

const WatchersManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [actionType, setActionType] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [adsWatchedpage, setAdsWatchedPage] = useState<number>(1);
  const [showActionModal, setShowActionModal] = useState<boolean>(false);
  const [showAEditModal, setShowAEditModal] = useState<boolean>(false);
  const [user, setUser] = useState<{ name: string; id: number } | null>(null);
  const [activeTab, setActiveTab] = useState<StatusType>("ACTIVE");

  const [initialValues, setInitialValues] = useState<EditUserFieldType>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    username: "",
    gender: "",
  });

  const [form] = Form.useForm<EditUserFieldType>();

  // Access the client
  const queryClient = useQueryClient();

  const { data: watchersData, isPending: isPendingWatchers } = useQuery({
    queryKey: [QUERY_KEY_WATCHERS, page, search, activeTab],
    queryFn: async () =>
      getWatchers({
        limit: PAGINATION_LIMIT,
        page,
        search,
        status: activeTab,
      }),
  });

  const { data: watcherData, isPending: isPendingWatcher } = useQuery({
    queryKey: [QUERY_KEY_WATCHER, id],
    queryFn: async () => (id ? getWatcher(`${id}`) : undefined),
  });

  const { data: watcherStatsData, isPending: isPendingWatcherStats } = useQuery(
    {
      queryKey: [QUERY_KEY_ADS_WATCHER_STATS, id],
      queryFn: async () => (id ? getWatcherStats(`${id}`) : undefined),
    }
  );

  const { data: adsWatchedData, isPending: isPendingAdsWatched } = useQuery({
    queryKey: [QUERY_KEY_ADS_WATCHED, id, adsWatchedpage],
    queryFn: async () =>
      id
        ? getAdsWatched({
            limit: PAGINATION_LIMIT,
            page,
            userId: `${id}`,
          })
        : undefined,
  });

  const {
    mutateAsync: mutateDeleteWatcher,
    isPending: isPendingDeleteWatcher,
  } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_WATCHERS],
      });
    },
  });

  const { mutateAsync: mutateBanWatcher, isPending: isPendingBanWatcher } =
    useMutation({
      mutationFn: banUser,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_WATCHERS],
        });
      },
    });

  const {
    mutateAsync: mutateSuspendWatcher,
    isPending: isPendingSuspendWatcher,
  } = useMutation({
    mutationFn: suspendUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_WATCHERS],
      });
    },
  });

  const { mutateAsync: mutateEditWatcher, isPending: isPendingEditWatcher } =
    useMutation({
      mutationFn: updateUserProfile,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_WATCHERS],
        });
      },
    });

  const {
    mutateAsync: mutateUnSuspendWatcher,
    isPending: isPendingUnSuspendWatcher,
  } = useMutation({
    mutationFn: unSuspendUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_WATCHERS],
      });
    },
  });

  const handleClick = (id: number) => {
    setIsOpen(true);
    setId(id);
  };

  const handleEdit = async (values: EditUserFieldType) => {
    console.log(values);
    // Compare the new values with the initial values
    const updatedValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (value !== initialValues[key as keyof EditUserFieldType]) {
        acc[key as keyof EditUserFieldType] = value;
      }
      return acc;
    }, {} as Partial<EditUserFieldType>);

    // If no values have changed, return early
    if (Object.keys(updatedValues).length === 0) {
      message.info("No changes detected.");
      return;
    }
    const res = await mutateEditWatcher({
      id: `${id}`,
      ...updatedValues, // Send only updated values
    });

    if ("error" in res) {
      message.error(res?.message ?? res.error);
      return;
    } else {
      message.success(res.message ?? "User updated successfully!");
    }
    setShowAEditModal(false);
  };

  //table column
  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      key: "firstName",
      render: (_, { userImg, id, firstName, lastName }) => (
        <StyledFlex onClick={() => handleClick(id)} gap="12px" align="center">
          <UserImg src={userImg} alt="" />
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {firstName ?? "-"} {lastName ?? "-"}
          </StyledTypography>
        </StyledFlex>
      ),
      width: "20%",
    },
    {
      title: "Email",
      key: "email",
      render: (_, { email, id }) => (
        <StyledTypography
          onClick={() => handleClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {email ?? "-"}
        </StyledTypography>
      ),
      width: "15%",
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      render: (_, { phoneNumber, id }) => (
        <StyledTypography
          onClick={() => handleClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {phoneNumber ?? "-"}
        </StyledTypography>
      ),
      width: "12%",
    },
    {
      title: "Gender",
      key: "gender",
      render: (_, { gender, id }) => (
        <StyledTypography
          onClick={() => handleClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {gender ?? "-"}
        </StyledTypography>
      ),
      width: "8%",
    },
    {
      title: "Age",
      key: "age",
      render: (_, { age, id }) => (
        <StyledTypography
          onClick={() => handleClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {age ?? "-"}
        </StyledTypography>
      ),
      width: "5%",
    },
    {
      title: " Status",
      key: "status",
      render: (_, { status, id }) => (
        <StyledTypography
          onClick={() => handleClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {status ?? "-"}
        </StyledTypography>
      ),
      width: "13%",
    },
    {
      title: " Date Joined",
      key: "date",
      render: (_, { date, id }) => (
        <StyledTypography
          onClick={() => handleClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {date ?? "-"}
        </StyledTypography>
      ),
      width: "11%",
    },
    {
      title: "",
      key: "date",
      render: (
        _,
        {
          id,
          firstName,
          lastName,
          status,
          gender,
          address,
          userName,
          email,
          phoneNumber,
        }
      ) => (
        <Dropdown
          dropdownRender={() => {
            return <></>;
          }}
          menu={{
            items: [
              {
                label: (
                  <button
                    onClick={() => {
                      form.setFieldsValue({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phoneNumber: phoneNumber,
                        address: address,
                        username: userName,
                        gender,
                      });
                      setInitialValues({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phoneNumber: phoneNumber,
                        address: address,
                        username: userName,
                        gender,
                      });
                      setId(id);
                      setShowAEditModal(true);
                    }}
                  >
                    <StyledTypography
                      // onClick={() => handleClick(id)}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Edit
                    </StyledTypography>
                  </button>
                ),
                key: "0",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: `${firstName} ${lastName}` });
                        setShowActionModal(true);
                        setActionType("delete");
                      }}
                      $variantColor="red"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Delete
                    </StyledTypography>
                  </button>
                ),
                key: "1",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: `${firstName} ${lastName}` });
                        setShowActionModal(true);
                        setActionType(
                          status !== "SUSPENDED" ? "suspend" : "unsuspend"
                        );
                      }}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      {status !== "SUSPENDED" ? "Suspend" : "Unsuspend"}
                    </StyledTypography>
                  </button>
                ),
                key: "2",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: `${firstName} ${lastName}` });
                        setShowActionModal(true);
                        setActionType("ban");
                      }}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Ban
                    </StyledTypography>
                  </button>
                ),
                key: "3",
              },
            ],
          }}
        >
          <button>
            <Options />
          </button>
        </Dropdown>
      ),
      width: "4%",
    },
  ];

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
        setPage(1);
      },
      1000,
      false
    ),
    []
  );

  if (watchersData && "error" in watchersData) {
    return null;
  }

  const watcher =
    watcherData && "error" in watcherData ? null : watcherData?.data;
  const profile = watcher?.profile;

  const watcherStats =
    watcherStatsData && "error" in watcherStatsData
      ? null
      : watcherStatsData?.data;

  const adsWatched =
    adsWatchedData && "error" in adsWatchedData ? null : adsWatchedData?.data;

  const actionText =
    actionType === "delete"
      ? "Delete"
      : actionType === "ban"
      ? "Ban"
      : actionType === "suspend"
      ? "Suspend"
      : "Unsuspend";

  return (
    <>
      {/* edit watcher details modal */}
      <Modal
        width={788}
        isModalOpen={showAEditModal}
        setIsModalOpen={setShowAEditModal}
        heading="Edit Profile"
        footer={
          <StyledFlex justify="flex-end">
            <StyledButton
              onClick={() => {
                setShowAEditModal(false);
              }}
              type="default"
            >
              Cancel
            </StyledButton>
            <StyledButton
              key="submit"
              htmlType="submit"
              form="edit-watcher"
              type="primary"
              loading={isPendingEditWatcher}
              disabled={isPendingEditWatcher}
            >
              Update
            </StyledButton>
          </StyledFlex>
        }
      >
        <StyledForm
          form={form}
          layout="vertical"
          name="edit-watcher"
          onFinish={handleEdit}
        >
          <StyledFlex gap={16} vertical $alignSelf="stretch">
            <StyledFlex align="center" gap={24}>
              <StyledForm.Item<EditUserFieldType>
                name="firstName"
                rules={[required("Please input the first name!")]}
                label="First Name"
              >
                <StyledInput type="text" placeholder="{WatchersFirstName}" />
              </StyledForm.Item>
              <StyledForm.Item<EditUserFieldType>
                name="lastName"
                rules={[required("Please input the last name!")]}
                label="Last Name"
              >
                <StyledInput type="text" placeholder="{WatchersLastName}" />
              </StyledForm.Item>
            </StyledFlex>

            <StyledFlex align="center" gap={24}>
              <StyledForm.Item<EditUserFieldType>
                name="email"
                rules={[
                  required("Please input your Email!"),
                  {
                    type: "email",
                    message: "Please input a valid Email!",
                  },
                ]}
                label="Email"
              >
                <StyledInput
                  disabled
                  prefix={<Email />}
                  type="text"
                  placeholder="Enter Email"
                />
              </StyledForm.Item>
              <StyledForm.Item<EditUserFieldType>
                name="phoneNumber"
                // rules={[required("Please input your Phone number!")]}
                label="Phone Number"
              >
                <StyledInput
                  prefix={<Phone />}
                  type="text"
                  placeholder="Enter a phone number"
                />
              </StyledForm.Item>
            </StyledFlex>

            <StyledFlex align="center" gap={24}>
              <StyledForm.Item<EditUserFieldType>
                name="gender"
                // rules={[required("Please input the gender!")]}
                label="Gender"
              >
                <Select
                  placeholder="Select a gender"
                  options={[
                    {
                      label: "MALE",
                      value: "MALE",
                    },
                    {
                      label: "FEMALE",
                      value: "FEMALE",
                    },
                  ]}
                />
              </StyledForm.Item>
              <StyledForm.Item<EditUserFieldType>
                name="username"
                rules={[required("Please input the username!")]}
                label="User Name"
              >
                <StyledInput
                  prefix={<At />}
                  type="text"
                  placeholder="Enter a username"
                />
              </StyledForm.Item>
            </StyledFlex>

            <StyledForm.Item<EditUserFieldType>
              name="address"
              // rules={[required("Please input the Location!")]}
              label="Watcher’s Adress"
            >
              <StyledInput type="text" placeholder="Enter an address" />
            </StyledForm.Item>
          </StyledFlex>
        </StyledForm>
      </Modal>
      <ConfigProvider theme={watchersManagementAntdTheme}>
        {/* view watcher details slide in */}
        <SlideInDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <StyledFlex align="center" gap={38} vertical>
            <StyledFlex $alignSelf="stretch" align="center" gap={16}>
              <UserProfile src={profile?.avatar?.publicURL ?? Avatar} alt="" />
              <StyledFlex flex={"1"} align="flex-start" gap={8} vertical>
                <StyledTypography
                  $variantColor="black100"
                  $variantSize="2xl"
                  $fontWeight={600}
                >
                  {profile?.firstName ?? ""} {profile?.lastName ?? ""}
                </StyledTypography>
                <StyledTypography $variantColor="black80" $variantSize="md">
                  <StyledFlex as="span" align="center" gap={8}>
                    <span>@{profile?.username ?? ""}</span> <span> | </span>
                    <span>{watcher?.email ?? ""} </span>
                    <span>| </span>
                    <span>{profile?.phoneNumber ?? ""} </span>
                  </StyledFlex>
                </StyledTypography>
                <StyledTypography $variantColor="black80" $variantSize="md">
                  Date Joined:{" "}
                  {watcher?.createdAt
                    ? DateTime.fromISO(watcher?.createdAt).toFormat(
                        "MMM dd, yyyy"
                      )
                    : ""}
                </StyledTypography>
              </StyledFlex>
              <WatchersBalance gap={8} align="center" vertical>
                <StyledTypography
                  $variantColor="white"
                  $variantSize="xs"
                  $fontWeight={400}
                >
                  Current Balance
                </StyledTypography>
                <StyledFlex align="center" gap={4}>
                  <StyledTypography
                    $variantColor="white"
                    $variantSize="lg"
                    $fontWeight={500}
                    as="p"
                  >
                    {watcher?.wallet?.balance ?? "0"}
                  </StyledTypography>
                  <StyledTypography
                    $variantColor="white"
                    $variantSize="xs"
                    $fontWeight={400}
                    as="p"
                  >
                    {" "}
                    Shekels
                  </StyledTypography>
                </StyledFlex>

                <StyledTypography
                  $variantColor="white"
                  $variantSize="xs"
                  $fontWeight={400}
                >
                  in Naira = N{" "}
                  {convertShekelsToNaira(
                    `${watcher?.wallet?.balance ?? "0"}`
                  )?.toLocaleString()}
                </StyledTypography>
              </WatchersBalance>
            </StyledFlex>
            <ProfileMetrics $alignSelf="stretch" justify="space-between">
              {[
                {
                  value: watcherStats?.viewedAds
                    ? abbreviateNumberToK(watcherStats?.viewedAds)
                    : "0",
                  title: "Viewed ads",
                },
                {
                  value: watcherStats?.likedComments
                    ? abbreviateNumberToK(watcherStats?.likedComments)
                    : "0",
                  title: "Liked comments",
                },
                {
                  value: watcherStats?.shekelsEarned
                    ? abbreviateNumberToK(watcherStats?.shekelsEarned)
                    : "0",
                  title: "Shekels Earned",
                },
                {
                  value: watcherStats?.tribes ?? "",
                  title: "Tribes",
                },
              ].map((item) => (
                <StyledFlex
                  key={item.title}
                  gap={8}
                  $alignSelf="stretch"
                  align="center"
                  vertical
                >
                  <StyledTypography
                    $variantColor="black100"
                    $variantSize="md"
                    $fontWeight={500}
                  >
                    {item.value}
                  </StyledTypography>
                  <StyledTypography $variantColor="black90" $variantSize="xs">
                    {item.title}
                  </StyledTypography>
                </StyledFlex>
              ))}
            </ProfileMetrics>

            <StyledFlex
              align="flex-start"
              gap={16}
              vertical
              $alignSelf="stretch"
            >
              <StyledTypography
                as="p"
                $variantColor="black100"
                $variantSize="lg"
                $fontWeight={500}
              >
                Ad Watched
              </StyledTypography>
              <Table
                columns={adColumns}
                dataSource={
                  adsWatched && adsWatched?.length
                    ? adsWatched?.map(({ advertisement, ...rest }) => ({
                        videoImage: "",
                        videoTitle: advertisement?.title ?? "",
                        videoContent: advertisement?.description ?? "",
                        advertiser:
                          advertisement?.user?.profile?.username ?? "",
                        tribe: Object.values(advertisement?.tribe ?? ""),
                        earnings: advertisement?.earnings?.amount ?? 0,
                        date: DateTime.fromISO(
                          advertisement?.earnings?.createdAt
                        ).toFormat("MMM dd, yyyy"),
                      }))
                    : []
                }
                pageSize={5}
                loading={isPendingAdsWatched}
                onPaginationChange={(page) => {
                  setAdsWatchedPage(page);
                  console.log(page);
                }}
                isPaginated={true}
                total={
                  adsWatchedData && "error" in adsWatchedData
                    ? 0
                    : adsWatchedData?.totalDocuments ?? 0
                }
              />
            </StyledFlex>
          </StyledFlex>
        </SlideInDrawer>
        {/* delete/ban/suspend/unsuspend modal  */}
        <Modal
          isModalOpen={showActionModal}
          setIsModalOpen={setShowActionModal}
          heading={`Are you sure you want to ${actionText} this Watcher?`}
          footer={
            <StyledFlex justify="flex-end">
              <StyledButton
                onClick={() => {
                  setShowActionModal(false);
                }}
                type="default"
              >
                Cancel
              </StyledButton>
              <StyledDeleteButton
                loading={
                  isPendingDeleteWatcher ||
                  isPendingSuspendWatcher ||
                  isPendingUnSuspendWatcher ||
                  isPendingBanWatcher
                }
                form="suspend-adds"
                key="submit"
                htmlType="submit"
                type="primary"
                onClick={async () => {
                  if (actionType === "delete") {
                    const res = await mutateDeleteWatcher({
                      id: `${user?.id}`,
                    });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe deleted successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                  if (actionType === "ban") {
                    const res = await mutateBanWatcher({ id: `${user?.id}` });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe banned successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                  if (actionType === "suspend") {
                    const res = await mutateSuspendWatcher({
                      id: `${user?.id}`,
                    });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe suspended successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                  if (actionType === "unsuspend") {
                    const res = await mutateUnSuspendWatcher({
                      id: `${user?.id}`,
                    });
                    if ("error" in res) {
                      message.error(res?.message ?? res.error);
                      return;
                    } else {
                      message.success(
                        res.message ?? "Tribe unsuspended successfully!"
                      );
                      setShowActionModal(false);
                    }
                  }
                }}
              >
                Yes, I want to {actionText} this Watcher
              </StyledDeleteButton>
            </StyledFlex>
          }
        >
          <StyledFlex gap={38} vertical $alignSelf="stretch">
            <StyledTypography as="p" $variantColor="black100" $variantSize="md">
              You are about to {actionText}{" "}
              <StyledTypography
                as="span"
                $variantColor="black100"
                $variantSize="md"
                $fontWeight={500}
              >
                “{user?.name}”.
              </StyledTypography>
            </StyledTypography>
          </StyledFlex>
        </Modal>
        <WatchersManagementLayout gap={32} vertical $alignSelf="stretch">
          <StyledTypography
            $variantColor="gray90"
            $variantSize="2xl"
            $fontWeight={500}
          >
            Watchers Management
          </StyledTypography>
          <StyledDivider />
          <StyledFlex justify="space-between">
            <StyledFlex>
              {["Active", "Suspended", "Banned"].map((item) => (
                <StyledStatusTab
                  key={item}
                  isActive={activeTab === item.toLocaleUpperCase()}
                  onClick={() => setActiveTab(item.toUpperCase() as StatusType)}
                >
                  {item} User
                </StyledStatusTab>
              ))}
            </StyledFlex>
            <SearchContainer>
              <StyledInput
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                prefix={<Search />}
                placeholder="Search"
              />
            </SearchContainer>
          </StyledFlex>
          <Table
            className="watchers-table"
            pageSize={PAGINATION_LIMIT}
            loading={isPendingWatchers}
            onPaginationChange={(page) => {
              setPage(page);
              console.log(page);
            }}
            columns={columns}
            dataSource={
              watchersData && watchersData?.data.length
                ? watchersData?.data.map(
                    ({ id, email, createdAt, profile, ...rest }) => ({
                      key: `${id}`,
                      id,
                      userImg: profile?.avatar
                        ? profile?.avatar?.publicURL
                        : Avatar,
                      userName: profile?.username ?? "",
                      gender: profile?.gender ?? "",
                      address: profile?.address ?? "",
                      email,
                      phoneNumber: profile?.phoneNumber ?? "",
                      date: DateTime.fromISO(createdAt).toFormat(
                        "MMM dd, yyyy"
                      ),
                      age: profile?.dateOfBirth
                        ? calculateAge(new Date(profile?.dateOfBirth ?? ""))
                        : "",
                      status: rest?.status,
                      firstName: profile?.firstName ?? "",
                      lastName: profile?.lastName ?? "",
                    })
                  )
                : []
            }
            isPaginated={true}
            total={(watchersData && watchersData.totalDocuments) ?? 0}
          />
          {/* <StyledTabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
        </WatchersManagementLayout>
      </ConfigProvider>
    </>
  );
};

export default WatchersManagement;
