import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  AdminManagementLayout,
  StyledActionBtn,
  StyledTableImg,
} from "./AdminManagement.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { Delete, Edit, Plus, Search } from "@/assets/vectors";
import { StyledButton } from "@/components/Dashboard/Button/Button.styles";
import type { ColumnsType } from "antd/es/table";
import AvartarImg from "@/assets/images/avartar.png";
import { StyledPill } from "@/components/Dashboard/Pill/Pill.styles";
import Table from "@/components/Dashboard/Table/Table";
import { useState } from "react";
import Modal from "@/components/Dashboard/Modal/Modal";
import Select from "@/components/Dashboard/Select/Select";
import { AdminFieldType } from "./AdminManagement.types";
import { StyledForm } from "@/components/Dashboard/Form/Form.styles";
import { required } from "@/utils/validation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createAdmin } from "@/network/auth";
import { Form, message } from "antd";
import AdminManagementForm from "./AdminManagementForm";
import { PAGINATION_LIMIT, QUERY_KEY_ADMINS } from "@/constants/api";
import { getAdmins, updateUserProfile } from "@/network/user";
import { DateTime } from "luxon";
import Avatar from "@/assets/images/avatar.svg";

interface DataType {
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  date: string;
  role: string;
  phoneNumber: string;
}

const AdminManagement = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [user, setUser] = useState<AdminFieldType | null>(null);
  const [id, setId] = useState<number | null>(null);

  // Access the client
  const queryClient = useQueryClient();

  const { mutateAsync: mutateAddAdmin, isPending: isPendingAddAdmin } =
    useMutation({
      mutationFn: createAdmin,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_ADMINS],
        });
      },
    });

  const { mutateAsync: mutateEditAdmin, isPending: isPendingEditAdmin } =
    useMutation({
      mutationFn: updateUserProfile,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_ADMINS],
        });
      },
    });

  const showModal = () => {
    setIsAddModalOpen(true);
  };

  const { data: adminsData, isPending: isPendingAdmins } = useQuery({
    queryKey: [QUERY_KEY_ADMINS, page],
    queryFn: async () =>
      getAdmins({
        limit: PAGINATION_LIMIT,
        page,
      }),
  });

  const onFinishAdd = async (values: AdminFieldType) => {
    console.log("Success:", values);
    const res = await mutateAddAdmin({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      redirectUrl: "https://admin.staging.lohli.com/verify-signup",
    });

    if ("error" in res) {
      message.error(res?.message ?? res.error);
      return;
    } else {
      message.success(res.message ?? "Verificiation email sent to admin!");
    }

    setIsAddModalOpen(false);
    return;
  };

  const onFinishEdit = async (values: AdminFieldType) => {
    // Compare the new values with the initial values
    const updatedValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (user && value !== user[key as keyof AdminFieldType]) {
        acc[key as keyof AdminFieldType] = value;
      }
      return acc;
    }, {} as Partial<AdminFieldType>);

    // If no values have changed, return early
    if (Object.keys(updatedValues).length === 0) {
      message.info("No changes detected.");
      return;
    }
    const res = await mutateEditAdmin({
      id: `${id}`,
      // Send only updated values,
      firstName: updatedValues.firstName,
      lastName: updatedValues.lastName,
      phoneNumber: updatedValues.phoneNumber,
    });

    if ("error" in res) {
      message.error(res?.message ?? res.error);
      return;
    } else {
      message.success(res.message ?? "Admin updated successfully!");
    }
    setIsEditModalOpen(false);
    return;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      key: "name",
      render: (_, { firstName, lastName, image }) => (
        <StyledFlex gap={12} align="center">
          <StyledTableImg src={image} alt="" />
          <StyledTypography $variantColor="gray90" $variantSize="sm">
            {`${firstName} ${lastName}`}
          </StyledTypography>
        </StyledFlex>
      ),
      width: "34%",
    },
    {
      title: "Email address",
      key: "email",
      render: (_, { email }) => (
        <StyledTypography $variantColor="gray500" $variantSize="sm">
          {email}
        </StyledTypography>
      ),
      width: "22%",
    },
    {
      title: "Role",
      key: "role",
      render: (_, { role }) => (
        // <StyledPill $bgColor="success50" $color="success700">
        //   Relationship Manager
        // </StyledPill>
        <StyledTypography $variantColor="gray500" $variantSize="sm">
          {role}
        </StyledTypography>
      ),
      width: "19%",
    },
    {
      title: "Date added",
      key: "date",
      render: (_, { date }) => (
        <StyledTypography $variantColor="gray500" $variantSize="sm">
          {date}
        </StyledTypography>
      ),
      width: "14%",
    },
    {
      title: "",
      key: "key",
      render: (
        _,
        { date, email, image, key, firstName, lastName, phoneNumber, role }
      ) => (
        <StyledFlex gap={4} align="center">
          <StyledActionBtn>
            <Delete />
          </StyledActionBtn>
          <StyledActionBtn
            onClick={() => {
              setIsEditModalOpen(true);
              setUser({
                email,
                firstName,
                lastName,
                phoneNumber,
              });
              setId(parseInt(key));
            }}
          >
            <Edit />
          </StyledActionBtn>
        </StyledFlex>
      ),
      width: "11%",
    },
  ];

  if (adminsData && "error" in adminsData) {
    return null;
  }

  const admins = adminsData && "error" in adminsData ? null : adminsData?.data;

  return (
    <AdminManagementLayout gap={32} vertical $alignSelf="stretch">
      <AdminManagementForm
        type="create"
        isLoading={isPendingAddAdmin}
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        onSubmit={async (values) => {
          await onFinishAdd(values);
          return;
        }}
        initialValues={null}
      />
      <AdminManagementForm
        type="edit"
        isLoading={isPendingEditAdmin}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        onSubmit={async (values) => {
          await onFinishEdit(values);
          return;
        }}
        initialValues={
          user
            ? {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phoneNumber,
                role: "Admin",
              }
            : null
        }
      />
      <StyledFlex align="center" justify="space-between">
        <StyledFlex vertical gap={4}>
          <StyledFlex align="center" gap={8}>
            <StyledTypography
              $variantColor="gray90"
              $variantSize="xl-plus"
              $fontWeight={500}
            >
              Admin Members
            </StyledTypography>
            <StyledPill $bgColor="secondary2" $color="black100">
              {(adminsData && adminsData.totalDocuments) ?? 0} users
            </StyledPill>
          </StyledFlex>
          <StyledTypography
            $variantColor="gray500"
            $variantSize="md"
            $fontWeight={400}
          >
            Manage your admin members here
          </StyledTypography>
        </StyledFlex>
        <StyledButton onClick={() => showModal()} type="primary">
          <Plus />
          <span>Add Admin User</span>
        </StyledButton>
      </StyledFlex>
      <Table
        columns={columns}
        pageSize={PAGINATION_LIMIT}
        loading={isPendingAdmins}
        onPaginationChange={(page) => {
          setPage(page);
          console.log(page);
        }}
        isPaginated
        dataSource={
          admins && admins?.length
            ? admins?.map(({ id, email, createdAt, profile, ...rest }) => ({
                key: `${id}`,
                id,
                email,
                image: profile?.avatar ? profile?.avatar?.publicURL : Avatar,
                date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
                role: "Admin",
                firstName: profile?.firstName ?? " ",
                lastName: profile?.lastName ?? " ",
                phoneNumber: profile?.phoneNumber ?? "",
              }))
            : []
        }
        total={(adminsData && adminsData.totalDocuments) ?? 0}
      />
    </AdminManagementLayout>
  );
};

export default AdminManagement;
