import { Lock } from "@/assets/vectors";
import { StyledButton } from "@/components/Auth/Button/Button.style";
import { StyledForm } from "@/components/Auth/Form/Form.styles";
import FormHeader from "@/components/Auth/FormHeader/FormHeader";
import { StyledInput } from "@/components/Auth/Input/Input.style";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import AuthLayout from "@/layouts/AuthLayout/AuthLayout";
import { CreatePasswordFieldType } from "./CreatePassword.types";
import { Form, message } from "antd";
import { required } from "@/utils/validation";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from "@/constants/api";
import { useMutation } from "@tanstack/react-query";
import { verifyAdmin } from "@/network/auth";

const CreatePassword: FC = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: verifyAdmin,
  });

  const [params] = useSearchParams();

  const onFinish = async ({
    confirmPassword,
    initialPassword,
  }: CreatePasswordFieldType) => {
    const email = params.get("email");
    const token = params.get("token");
    if (typeof email == "string" && typeof token === "string") {
      const res = await mutateAsync({
        currentPassword: initialPassword,
        email,
        newPassword: confirmPassword,
        token,
      });
      if ("error" in res) {
        message.error(res.message);
        window.location.replace("/");
        return;
      } else {
        sessionStorage.removeItem(TOKEN_NAME);
        sessionStorage.removeItem(REFRESH_TOKEN_NAME);
        sessionStorage.setItem(TOKEN_NAME, res.data.tokens.accessToken);
        sessionStorage.setItem(
          REFRESH_TOKEN_NAME,
          res.data.tokens.refreshToken
        );
        window.location.replace("/");
      }
    }
  };

  return (
    <AuthLayout>
      <StyledForm name="create-password" onFinish={onFinish}>
        <StyledFlex vertical align="flex-start" gap={32} $alignSelf="stretch">
          <FormHeader
            heading="Set your Password"
            subHeading={`Hi, ${params.get("email") ?? ""}`}
          />
          <StyledFlex gap={20} vertical align="stretch" $alignSelf="stretch">
            <Form.Item<CreatePasswordFieldType>
              name="initialPassword"
              rules={[required("Please input your Password")]}
            >
              <StyledInput
                prefix={<Lock />}
                placeholder="Input Password sent to your mail"
                type="password"
              />
            </Form.Item>
            <Form.Item<CreatePasswordFieldType>
              name="password"
              rules={[required("Please input your Password")]}
            >
              <StyledInput
                prefix={<Lock />}
                placeholder="New Password"
                type="password"
              />
            </Form.Item>
            <Form.Item<CreatePasswordFieldType>
              name="confirmPassword"
              rules={[
                required("Please input your Password"),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <StyledInput
                prefix={<Lock />}
                placeholder="Confirm New Password"
                type="password"
              />
            </Form.Item>
          </StyledFlex>
        </StyledFlex>
        <StyledButton
          disabled={isPending}
          loading={isPending}
          type="primary"
          htmlType="submit"
        >
          Continue
        </StyledButton>
      </StyledForm>
    </AuthLayout>
  );
};

export default CreatePassword;
