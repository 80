import { Download } from "@/assets/vectors";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import Table from "@/components/Dashboard/Table/Table";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  PerformingTribesTableData,
  TopPerformingTribesChart,
  TopPerformingTribesTable,
} from "./TopPerformingTribes.styles";
import PieChart from "@/components/Dashboard/PieChart/PieChart";
import { StyledExportButton } from "@/components/Dashboard/Button/Button.styles";
import type { ColumnsType } from "antd/es/table";
import {
  QUERY_KEY_TOP_PERFORMING_TRIBES,
  QUERY_KEY_TRIBE_PIE_CHART,
} from "@/constants/api";
import { useQuery } from "@tanstack/react-query";
import { getTopPerformingTribes, getTribePieChart } from "@/network/analytics";
import { FC, useState } from "react";
import { abbreviateNumberToK, getRandomColor } from "@/utils/helper-functions";
import { ISODateString } from "@/types/global";
import { ITopPerformingTribesProps } from "./TopPerformingTribes.types";

interface TribeDataType {
  key: string;
  tribe: string;
  shekels: string;
  view: string;
  likes: string;
  comments: string;
}

const columns: ColumnsType<TribeDataType> = [
  {
    title: "Tribe",
    key: "tribe",
    render: (_, { tribe }) => (
      <PerformingTribesTableData>
        <StyledTypography $variantColor="black100" $variantSize="sm">
          {tribe}
        </StyledTypography>
      </PerformingTribesTableData>
    ),
    width: "37%",
  },
  {
    title: "Shekels Earned",
    key: "shekels",
    render: (_, { shekels }) => (
      <PerformingTribesTableData>
        <StyledTypography $variantColor="black100" $variantSize="sm">
          {shekels}
        </StyledTypography>
      </PerformingTribesTableData>
    ),
    width: "22%",
  },
  {
    title: "View",
    key: "view",
    render: (_, { view }) => (
      <PerformingTribesTableData>
        <StyledTypography $variantColor="black100" $variantSize="sm">
          {view}
        </StyledTypography>
      </PerformingTribesTableData>
    ),
    width: "12%",
  },
  {
    title: "Likes",
    key: "likes",
    render: (_, { likes }) => (
      <PerformingTribesTableData>
        <StyledTypography $variantColor="black100" $variantSize="sm">
          {likes}
        </StyledTypography>
      </PerformingTribesTableData>
    ),
    width: "12%",
  },
  {
    title: "Comments",
    key: "comments",
    render: (_, { comments }) => (
      <PerformingTribesTableData>
        <StyledTypography $variantColor="black100" $variantSize="sm">
          {comments}
        </StyledTypography>
      </PerformingTribesTableData>
    ),
    width: "17%",
  },
];

const TRIBE_PAGINATION_LIMIT = 5 as const;

const TopPerformingTribes: FC<ITopPerformingTribesProps> = ({
  dateRange,
  advertiserId,
  tribeId,
}) => {
  const [page, setPage] = useState<number>(1);
  const {
    data: topPerformingTribesData,
    isPending: isPendingTopPerformingTribes,
  } = useQuery({
    queryKey: [
      QUERY_KEY_TOP_PERFORMING_TRIBES,
      dateRange,
      tribeId,
      advertiserId,
    ],
    queryFn: async () =>
      getTopPerformingTribes({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
        advertiserId: advertiserId || undefined,
        tribeId: tribeId || undefined,
      }),
  });
  const { data: pieChartData } = useQuery({
    queryKey: [QUERY_KEY_TRIBE_PIE_CHART, dateRange, tribeId, advertiserId],
    queryFn: async () =>
      getTribePieChart({
        startDate: dateRange[0] as ISODateString,
        endDate: dateRange[1] as ISODateString,
        advertiserId: advertiserId || undefined,
        tribeId: tribeId || undefined,
      }),
  });

  const topPerformingTribes =
    topPerformingTribesData && "error" in topPerformingTribesData
      ? null
      : topPerformingTribesData;

  const pieChart =
    pieChartData && "error" in pieChartData ? null : pieChartData?.data;

  return (
    <>
      <StyledFlex justify="space-between" align="center">
        <StyledTypography
          $variantColor="black100"
          $variantSize="lg"
          $fontWeight={500}
        >
          Top Performing Tribes
        </StyledTypography>
        <StyledExportButton type="primary">
          <Download />
          <span>Export</span>
        </StyledExportButton>
      </StyledFlex>
      <StyledFlex justify="space-between">
        <TopPerformingTribesTable>
          <Table
            pageSize={TRIBE_PAGINATION_LIMIT}
            onPaginationChange={(page) => {
              setPage(page);
            }}
            columns={columns}
            dataSource={
              topPerformingTribes && topPerformingTribes?.data?.length
                ? topPerformingTribes?.data?.map((data) => ({
                    key: data.id,
                    view: abbreviateNumberToK(data?.totalViews ?? 0),
                    likes: abbreviateNumberToK(data?.totalLikes ?? 0),
                    comments: abbreviateNumberToK(data?.totalComments ?? 0),
                    shekels: data?.shekelsEarned ?? "",
                    tribe: data?.name ?? "",
                  }))
                : []
            }
            isPaginated={true}
            total={
              (topPerformingTribes && topPerformingTribes?.data?.length) ?? 0
            }
            loading={isPendingTopPerformingTribes}
          />
        </TopPerformingTribesTable>
        <TopPerformingTribesChart>
          <StyledTypography
            $variantColor="black100"
            $variantSize="md"
            $fontWeight={500}
            $mb={"24px"}
            $wordBreak="keep-all"
          >
            Tribe Contribution to Revenue Distribution
          </StyledTypography>
          {pieChart ? (
            <PieChart
              categories={pieChart?.tribeContributions?.map((data) => ({
                color: getRandomColor(),
                label: data?.tribeName ?? "",
                percent: data?.amount ?? 0,
              }))}
              series={pieChart?.tribeContributions?.map((item) => item.amount)}
            />
          ) : null}
        </TopPerformingTribesChart>
      </StyledFlex>
    </>
  );
};

export default TopPerformingTribes;
