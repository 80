import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import {
  AdvertisersManagementTableContainer,
  AdvestisersImg,
  SearchContainer,
} from "./AdvertisersTable.styles";
import { StyledInput } from "@/components/Dashboard/Input/Input.styles";
import { StyledExportButton } from "@/components/Dashboard/Button/Button.styles";
import { Download, Options, Search } from "@/assets/vectors";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import type { ColumnsType } from "antd/es/table";
import Table from "@/components/Dashboard/Table/Table";
import {
  EditAdvertiserFieldType,
  DataType,
  IAdvertisersTableProps,
} from "./AdvertisersTable.types";
import { useCallback, useState } from "react";
import { ConfigProvider } from "antd";
import { advertisersManagementTableAntdTheme } from "./AdvertisersTable.theme";
import { PAGINATION_LIMIT, QUERY_KEY_ADVERTISERS } from "@/constants/api";
import { getAdvertisers } from "@/network/user";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { debounce } from "@/utils/helper-functions";
import Avatar from "@/assets/images/avatar.svg";
import Dropdown from "@/components/Dashboard/Dropdown/Dropdown";
import { StatusType } from "@/types/global";
import { StyledStatusTab } from "@/components/Dashboard/StatusTab/StatusTab.styles";
import SuspendDeleteBanAdvertiserModal from "../SuspendDeleteBanAdvertiserModal/SuspendDeleteBanAdvertiserModal";
import EditAdvertiserModal from "../EditAdvertiserModal/EditAdvertiserModal";

const AdvertisersTable: React.FC<IAdvertisersTableProps> = ({
  handleRowClick,
}) => {
  //states
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [showActionModal, setShowActionModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [user, setUser] = useState<{ name: string; id: number } | null>(null);
  const [actionType, setActionType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<StatusType>("ACTIVE");
  const [id, setId] = useState<number | null>(null);
  //initial values for the edit modal, to compare to new values for the edit form, (check edit button in table, & EditAdvertiserModal.tsx )
  const [initialValues, setInitialValues] = useState<EditAdvertiserFieldType>({
    phoneNumber: "",
    name: "",
    type: "",
    industry: "",
    description: "",
  });

  //query for advertisers
  const { data: advertisersData, isPending: isPendingAdvertisers } = useQuery({
    queryKey: [QUERY_KEY_ADVERTISERS, page, search, activeTab],
    queryFn: async () =>
      getAdvertisers({
        limit: PAGINATION_LIMIT,
        page,
        search,
        status: activeTab,
      }),
  });

  //search function, fires after 1 second of no typing
  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearch(value);
        setPage(1);
      },
      1000,
      false
    ),
    []
  );

  //table column
  const columns: ColumnsType<DataType> = [
    {
      title: "Advertisers",
      key: "userName",
      render: (_, { adImg, userName, id }) => (
        <StyledFlex
          onClick={() => handleRowClick(id)}
          gap="12px"
          align="center"
        >
          <AdvestisersImg src={adImg} alt="" />
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {userName}
          </StyledTypography>
        </StyledFlex>
      ),
      width: "20%",
    },
    {
      title: "Uploaded Ads",
      key: "uploadedAds",
      render: (_, { uploadedAds, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {uploadedAds}
        </StyledTypography>
      ),
      width: "11%",
    },
    {
      title: "Followers",
      key: "followers",
      render: (_, { followers, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {followers}
        </StyledTypography>
      ),
      width: "9%",
    },
    {
      title: "Views Accrued",
      key: "views",
      render: (_, { views, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {views}
        </StyledTypography>
      ),
      width: "12%",
    },
    {
      title: "Status",
      key: "status",
      render: (_, { status, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {status}
        </StyledTypography>
      ),
      width: "14%",
    },
    {
      title: " Date Joined",
      key: "date",
      render: (_, { date, id }) => (
        <StyledTypography
          onClick={() => handleRowClick(id)}
          $variantColor="black100"
          $variantSize="sm"
          $fontWeight={400}
        >
          {date}
        </StyledTypography>
      ),
      width: "10%",
    },
    {
      title: "",
      key: "id",
      render: (
        _,
        { id, userName, status, phoneNumber, description, name, type, industry }
      ) => (
        // action buttons, includes suspend, unsuspend, ban, delete, edit (opens modal)
        <Dropdown
          dropdownRender={() => {
            return <></>;
          }}
          menu={{
            items: [
              {
                label: (
                  <button
                    onClick={() => {
                      setShowEditModal(true);
                      setInitialValues({
                        phoneNumber,
                        description,
                        name,
                        type,
                        industry,
                      });
                      setId(id);
                    }}
                  >
                    <StyledTypography
                      // onClick={() => handleClick(id)}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Edit
                    </StyledTypography>
                  </button>
                ),
                key: "0",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: userName });
                        setShowActionModal(true);
                        setActionType("delete");
                      }}
                      $variantColor="red"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Delete
                    </StyledTypography>
                  </button>
                ),
                key: "1",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: userName });
                        setShowActionModal(true);
                        setActionType(
                          status !== "SUSPENDED" ? "suspend" : "unsuspend"
                        );
                      }}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      {status !== "SUSPENDED" ? "Suspend" : "Unsuspend"}
                    </StyledTypography>
                  </button>
                ),
                key: "2",
              },
              {
                label: (
                  <button>
                    <StyledTypography
                      onClick={() => {
                        setUser({ id: id, name: userName });
                        setShowActionModal(true);
                        setActionType("ban");
                      }}
                      $variantColor="black100"
                      $variantSize="sm"
                      $fontWeight={400}
                    >
                      Ban
                    </StyledTypography>
                  </button>
                ),
                key: "3",
              },
            ],
          }}
        >
          <button>
            <Options />
          </button>
        </Dropdown>
      ),
      width: "6%",
    },
  ];
  //end table column

  //handles error, return with any ui if there is an error
  if (advertisersData && "error" in advertisersData) {
    return null;
  }

  return (
    <AdvertisersManagementTableContainer gap={32} vertical $alignSelf="stretch">
      {/* modal starts */}
      <ConfigProvider theme={advertisersManagementTableAntdTheme}>
        {/* edit watcher details modal */}
        <EditAdvertiserModal
          id={id}
          initialValues={initialValues}
          setShowEditModal={setShowEditModal}
          showEditModal={showEditModal}
        />
        {/* delete/ban/suspend/unsuspend modal  */}
        <SuspendDeleteBanAdvertiserModal
          user={user}
          actionType={actionType}
          setShowActionModal={setShowActionModal}
          showActionModal={showActionModal}
        />
      </ConfigProvider>
      {/* modal ends */}

      <StyledFlex vertical gap={32}>
        {/* filter by status (Active, Suspended, Banned) */}
        <StyledFlex>
          {["Active", "Suspended", "Banned"].map((item) => (
            <StyledStatusTab
              key={item}
              isActive={activeTab === item.toLocaleUpperCase()}
              onClick={() => setActiveTab(item.toUpperCase() as StatusType)}
            >
              {item} User
            </StyledStatusTab>
          ))}
        </StyledFlex>
        {/* filter ends */}

        {/* search bar and export button */}
        <StyledFlex justify="space-between" align="center">
          <SearchContainer>
            <StyledInput
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              prefix={<Search />}
              placeholder="Search"
            />
          </SearchContainer>
          <StyledExportButton type="primary">
            <Download />
            <span>Export</span>
          </StyledExportButton>
        </StyledFlex>
        {/* search bar and export button ends */}
      </StyledFlex>

      {/* table for all advertisers based on status */}
      <Table
        className="advertisers-table"
        pageSize={PAGINATION_LIMIT}
        loading={isPendingAdvertisers}
        onPaginationChange={(page) => {
          setPage(page);
        }}
        columns={columns}
        dataSource={
          advertisersData && advertisersData?.data.length
            ? advertisersData?.data?.map(
                ({
                  profile,
                  email,
                  totalFollowing,
                  createdAt,
                  id,
                  advertisementMetrics,
                  totalAdvertisements,
                  advertiserInfo,
                  ...rest
                }) => ({
                  key: id,
                  id,
                  userName: profile?.username ?? "",
                  adImg: profile?.avatar?.publicURL ?? Avatar,
                  uploadedAds: totalAdvertisements ?? 0,
                  followers: totalFollowing,
                  views: advertisementMetrics?.totalViews ?? 0,
                  date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
                  status: rest.status,
                  phoneNumber: profile?.phoneNumber ?? "",
                  email,
                  description: advertiserInfo?.description ?? "",
                  industry: advertiserInfo?.industry ?? "",
                  name: advertiserInfo?.name ?? "",
                  type: advertiserInfo?.type ?? "",
                })
              )
            : []
        }
        total={(advertisersData && advertisersData?.totalDocuments) ?? 0}
        isPaginated
      />
      {/* table ends */}
    </AdvertisersManagementTableContainer>
  );
};

export default AdvertisersTable;
