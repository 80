import {
  IPaginationMeta,
  IPaginationParams,
  ISODateString,
  StatusType,
} from "@/types/global";
import API, { BaseResponse } from "./API";
import { IUser } from "@/types/user";
import Endpoints from "./endpoints";
import instance from "./axios";

// type SingleUser = Omit<IUser, "profile"> & {
//   profile: Omit<IUser["profile"], "avartar"> & {
//     avatar: { publicURL: string } | null;
//   };
// };

interface IPost {
  post: {
    id: number;
    body: string;
    reported: false;
    title: string;
    uuid: string;
    userId: number;
    tribeId: null;
    advertiserSubaccountId: number;
    relevanceScore: number;
    relevanceScoredAt: null;
    type: "ADVERTISER_POST";
    status: string;
    metricsGeneratedAt: ISODateString;
    createdAt: ISODateString;
    updatedAt: ISODateString;
    metadata: {
      id: number;
      likesStats: Record<string, number>;
      viewsStats: Record<string, number>;
      earnerStats: Record<string, number>;
      earnerGenderStats: Record<string, number>;
      earnerAgeStats: Record<string, number>;
      viewersLocationStats: {
        city: string;
        state: string;
        total: number;
        country: string;
      }[];
      totalLikes: number;
      totalReplies: number;
      totalBookmarks: number;
      totalComments: number;
      totalBudget: number;
      totalWatch: number;
      remainingPossibleWatch: number;
      totalViews: number;
      totalSpent: number;
      postId: number;
      advertisementId: number | null;
      commentId: number | null;
    };
    media: [
      {
        publicURL: string;
      }
    ];
    tribe: null | number;
    mentionedUsers: number[];
    user: {
      id: number;
      otherUserEngagement: [];
      profile: {
        username: string;
        avatar: string | null;
        firstName: string;
        lastName: string;
        gender: string;
      };
      totalFollowers: number;
      totalFollowing: number;
      totalAdvertisements: number;
      totalSocialPosts: number;
    };
  };
  engagement: null;
  followingUser: boolean;
}

interface IAdsWatched {
  actionType: "AD_WATCHED";
  advertisement: {
    advertiserSubaccountId: number;
    callToActionTitle: string;
    callToActionURL: string;
    costPerView: number;
    createdAt: ISODateString;
    deleted: boolean;
    description: string;
    id: number;
    locations: [];
    maxSpend: number;
    metadataId: null;
    metricsGeneratedAt: ISODateString;
    relevanceScore: number;
    relevanceScoredAt: ISODateString;
    status: string;
    thumbnailId: number;
    title: string;
    userId: number;
    videoId: number;
    user: IUser;
    transactions: {
      advertisementId: number;
      amount: number;
      createdAt: ISODateString;
      nairaAmount: number;
    }[];
    earnings: {
      amount: number;
      createdAt: ISODateString;
      nairaAmount: number;
    };
    tribe: Record<string, string>;
    tribeId: number;
  };
  advertisementId: number;
  amount: null | number;
  commentId: null | number;
  createdAt: ISODateString;
  deleted: false;
  id: number;
  isUserfollowingPoster: false;
  postId: null | number;
  transactionId: null | number;
  updatedAt: ISODateString;
  userId: number;
}

type SingleUser = IUser & {
  wallet: {
    createdAt: ISODateString;
    id: number;
    adBudget: number;
    adSpend: number;
    balance: number;
    userId: 322;
    updatedAt: ISODateString;
  };
};

interface ISingleWatcherStats {
  viewedAds: number;
  likedComments: number;
  shekelsEarned: number;
  tribes: number;
}

interface IUserParams {
  email?: string;
  search?: string;
  status?: StatusType;
}

interface IEditUser {
  username?: string;
  phoneNumber?: string;
  firstName?: string;
  bio?: string;
  lastName?: string;
  gender?: string;
  address?: string;
  dateOfBirth?: string;
  status?: StatusType;
}

interface IEditAdvertiser {
  name?: string;
  type?: string;
  industry?: string;
  description?: string;
  phoneNumber?: string;
}

interface ISuspendUser {
  reason: string;
  reactivateAfter: string;
}

interface ISignedUrlRequest {
  filename: string;
}

interface ISignedUrlResponse {
  id: number;
  userProfileId: number;
  userProfile: string;
  s3ey: string;
  deleted: boolean;
  s3Region: string;
  signedUrl: string;
  status: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
}

export const getAdvertisers = async (
  params: IPaginationParams & IUserParams
): Promise<BaseResponse<IUser[], IPaginationMeta>> => {
  return API.get<IUser[], IPaginationMeta>(Endpoints.USERS_SEARCH, {
    params: { ...params, role: "advertiser" },
  });
};

export const getRecentUsers = async (
  params: IPaginationParams & IUserParams
): Promise<BaseResponse<IUser[], IPaginationMeta>> => {
  return API.get<IUser[], IPaginationMeta>(Endpoints.USERS_SEARCH, {
    params: { ...params },
  });
};

export const getWatchers = async (
  params: IPaginationParams & IUserParams
): Promise<BaseResponse<IUser[], IPaginationMeta>> => {
  return API.get<IUser[], IPaginationMeta>(Endpoints.USERS_SEARCH, {
    params: { ...params, role: "user" },
  });
};

export const getAdmins = async (
  params: IPaginationParams & IUserParams
): Promise<BaseResponse<IUser[], IPaginationMeta>> => {
  return API.get<IUser[], IPaginationMeta>(Endpoints.USERS_SEARCH, {
    params: { ...params, role: "admin" },
  });
};

export const getCurrentAdmin = async (): Promise<BaseResponse<SingleUser>> => {
  return API.get<SingleUser>(`${Endpoints.USER}`);
};

export const updateCurrentAdmin = async (
  payload: IEditUser
): Promise<BaseResponse<IUser>> => {
  return API.patch<IEditUser, IUser>(`${Endpoints.USER}/profile`, payload);
};

export const createPresignedAvatarUrl = async (
  payload: ISignedUrlRequest
): Promise<BaseResponse<ISignedUrlResponse>> => {
  return API.post<ISignedUrlRequest, ISignedUrlResponse>(
    `${Endpoints.PRESIGNED_URL}`,
    payload
  );
};

export const getWatcher = async (
  id: string
): Promise<BaseResponse<SingleUser>> => {
  return API.get<SingleUser>(`${Endpoints.USERS}/${id}`);
};

export const getAdvertiser = async (
  id: string
): Promise<BaseResponse<SingleUser>> => {
  return API.get<SingleUser>(`${Endpoints.USERS}/${id}`);
};

export const getWatcherStats = async (
  id: string
): Promise<BaseResponse<ISingleWatcherStats>> => {
  return API.get<ISingleWatcherStats>(`${Endpoints.WATCHER_STATS}/${id}`);
};

export const getAdsWatched = async (
  params: IPaginationParams & { userId: string }
): Promise<BaseResponse<IAdsWatched[], IPaginationMeta>> => {
  return API.get<IAdsWatched[], IPaginationMeta>(`${Endpoints.ADS_WATCHED}`, {
    params,
  });
};

export const updateUserProfile = async ({
  id,
  ...body
}: IEditUser & { id: string }): Promise<BaseResponse<IUser>> => {
  return API.patch<Omit<IEditUser, "id">, IUser>(
    `${Endpoints.USERS}/${id}/profile`,
    body
  );
};

export const updateAdvertiserProfile = async ({
  id,
  ...body
}: IEditAdvertiser & { id: string }): Promise<BaseResponse<IUser>> => {
  return API.patch<Omit<IEditAdvertiser, "id">, IUser>(
    `${Endpoints.editAdvertiserInfo(id)}`,
    body
  );
};

export const deleteUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.delete<IUser>(`${Endpoints.USERS}/${id}`);
};

export const banUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.delete<IUser>(`${Endpoints.USERS}/${id}/ban`);
};

export const suspendUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.patch<ISuspendUser, IUser>(`${Endpoints.USERS}/${id}/suspend`);
};

export const unSuspendUser = async ({
  id,
}: {
  id: string;
}): Promise<BaseResponse<IUser>> => {
  return API.patch<ISuspendUser, IUser>(`${Endpoints.USERS}/${id}/unsuspend`);
};

export const getUploadedPosts = async (
  params: IPaginationParams & { userId: string }
): Promise<BaseResponse<IPost[], IPaginationMeta>> => {
  return API.get<IPost[], IPaginationMeta>(`${Endpoints.SOCIAL_POSTS}`, {
    params,
  });
};
