import { Layout } from "antd";
import styled from "styled-components";
import pattern from "@/assets/images/sidebar-pattern.png";
import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import { pixelToREM } from "@/utils/helper-functions";
import { NavLink } from "react-router-dom";

export const StyledLayout = styled(Layout)`
  &.ant-layout {
    background: none;
    min-height: 100vh;
  }
`;

export const StyledSidebar = styled(Layout.Sider)`
  &.ant-layout-sider {
    background: ${({ theme }) => `${theme.color.primary}`};
    height: 100vh;
    overflow-y: auto;
    position: sticky;
    top: 0;
    background: ${`url(${pattern})`};
  }
  & .ant-layout-sider-children {
    display: flex;
    padding: 20px 0px 48px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    flex-shrink: 0;
    align-self: stretch;
  }
`;

export const StyledLogo = styled.div`
  padding: 0px 20px;
`;

export const StyledMenuLinkList = styled(StyledFlex)`
  padding: 0px 16px;
  width: 100%;
`;
export const StyledMenuDropdownLinkList = styled(NavLink)`
  padding: 12px 16px;
  width: 100%;
  color: ${({ theme }) => `${theme.color.white}`};
  font-family: "Lexend";
  font-size: ${pixelToREM(16)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.15s ease-in-out;
  padding-left: 28px;
  &:hover,
  &.active {
    background-color: ${({ theme }) => `${theme.color.secondaryLight}`};
    color: ${({ theme }) => `${theme.color.white}`};
  }
`;

export const StyledMenuLinkName = styled.p`
  color: ${({ theme }) => `${theme.color.white}`};
  font-family: "Lexend";
  font-size: ${pixelToREM(16)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledMenuLinkItem = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 12px;
  width: 100%;
  &,
  & * {
    transition: 0.15s ease-in-out;
  }
  &:hover,
  &.active {
    background-color: ${({ theme }) => `${theme.color.secondary}`};
    path {
      stroke: ${({ theme }) => `${theme.color.black100}`};
    }
    ${StyledMenuLinkName} {
      color: ${({ theme }) => `${theme.color.black100}`};
    }
  }
`;

export const StyledAdminProfileContainer = styled(StyledFlex)`
  padding: 0 12px;
  width: 100%;
`;

export const StyledAdminProfile = styled(StyledFlex)`
  cursor: pointer;
  /* width: 205px;
  margin: 0 auto; */
`;

export const StyledAdminAvartar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
`;

export const StyledAdminName = styled(StyledTypography)`
  font-weight: 500;
`;

export const StyledAdminType = styled(StyledTypography)`
  color: rgba(255, 255, 255, 0.66);
`;

export const StyledMain = styled(Layout)`
  &.ant-layout {
    background: ${({ theme }) => `${theme.color.white}`};
    padding: 32px 32px 96px 32px;
    width: 100%;
    overflow-x: hidden;
  }
`;
