import { StyledFlex } from "@/components/Global/Flex/Flex.styles";
import styled from "styled-components";

export const AdvertisersManagementTableContainer = styled(StyledFlex)`
  width: 100%;
`;

export const SearchContainer = styled.div`
  width: 328px;
`;

export const FilterContainer = styled.div`
  width: 211px;
`;

export const AdvestisersImg = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const StyledStatusPill = styled.div`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.black50};
  border-radius: 16px;
  width: fit-content;
`;
