import { PAGINATION_LIMIT, QUERY_KEY_ADS_UPLOADED } from "@/constants/api";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import type { AdsDataType, IUploadedAdsProps } from "./UploadedAds.types";
import { getAdverts } from "@/network/advertisement";
import Table from "@/components/Dashboard/Table/Table";
import type { ColumnsType } from "antd/es/table";
import { StyledTypography } from "@/components/Global/Typography/Typgraphy.styles";
import {
  VideoContentData,
  VideoContentDataText,
  VideoContentImg,
} from "./UploadedAds.styles";
import { abbreviateNumberToK, applyEllipsis } from "@/utils/helper-functions";
import { DateTime } from "luxon";
import Avatar from "@/assets/images/avatar.svg";

//table column
const adColumns: ColumnsType<AdsDataType> = [
  {
    title: "Video Content",
    key: "videoTitle",
    render: (_, { videoContent, videoImage, videoTitle }) => (
      <VideoContentData>
        <VideoContentImg src={videoImage} alt="" />
        <VideoContentDataText>
          <StyledTypography
            as="p"
            $variantColor="black100"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoTitle, 37)}
          </StyledTypography>
          <StyledTypography
            as="p"
            $variantColor="black80"
            $variantSize="sm"
            $fontWeight={400}
          >
            {applyEllipsis(videoContent, 75)}
          </StyledTypography>
        </VideoContentDataText>
      </VideoContentData>
    ),
    width: "35%",
  },
  {
    title: "Views",
    key: "views",
    render: (_, { views, key }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {abbreviateNumberToK(views)}
      </StyledTypography>
    ),
    width: "9%",
  },
  {
    title: "Likes",
    key: "likes",
    render: (_, { likes, key }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {abbreviateNumberToK(likes)}
      </StyledTypography>
    ),
    width: "10%",
  },
  {
    title: "Comments",
    key: "comments",
    render: (_, { comments, key }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {abbreviateNumberToK(comments)}
      </StyledTypography>
    ),
    width: "12%",
  },
  {
    title: "Shekels Given",
    key: "earnings",
    render: (_, { earnings }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        {earnings.toLocaleString()} Shekels
      </StyledTypography>
    ),
    width: "16%",
  },
  {
    title: "Date Published",
    key: "date",
    render: (_, { date }) => (
      <StyledTypography
        $variantColor="black100"
        $variantSize="sm"
        $fontWeight={400}
      >
        Nov 24, 2023
      </StyledTypography>
    ),
    width: "18%",
  },
];

const UploadedAds: React.FC<IUploadedAdsProps> = ({ id }) => {
  //states
  const [page, setPage] = useState<number>(1);

  //queries
  const { data: uploadedAdsData, isPending: isPendingUploadedAds } = useQuery({
    queryKey: [QUERY_KEY_ADS_UPLOADED, id, page],
    queryFn: async () =>
      id
        ? getAdverts({
            limit: PAGINATION_LIMIT,
            page,
            userId: `${id}`,
          })
        : undefined,
    enabled: !!id,
  });

  const uploadedAds =
    uploadedAdsData && "error" in uploadedAdsData ? null : uploadedAdsData;
  console.log();

  return (
    <Table
      columns={adColumns}
      dataSource={
        uploadedAds?.data?.map(
          ({
            advert: { id, title, description, thumbnail, createdAt, metadata },
          }) => ({
            views: metadata?.totalViews ?? 0,
            likes: metadata?.totalLikes ?? 0,
            comments: metadata?.totalComments ?? 0,
            earnings: metadata?.totalSpent ?? 0,
            key: `${id}`,
            id,
            videoImage: thumbnail?.publicURL ?? Avatar,
            videoTitle: title,
            videoContent: description,
            date: DateTime.fromISO(createdAt).toFormat("MMM dd, yyyy"),
          })
        ) ?? []
      }
      pageSize={PAGINATION_LIMIT}
      total={(uploadedAds && uploadedAds?.totalDocuments) ?? 0}
      isPaginated={true}
      loading={isPendingUploadedAds}
      onPaginationChange={(page) => {
        setPage(page);
      }}
    />
  );
};

export default UploadedAds;
